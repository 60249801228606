import { Grid, Typography, Divider, Box } from '@mui/material'
import { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useLocation } from 'react-router'
import Artist from './artist'
import { AccountProvider, useAccountContext } from './context'
import Nav from '../app/nav'
import { TopRemixersList } from '../remixes/top-remixers'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: { display: 'none', [theme.breakpoints.up('md')]: { display: 'flex' } },
  sectionMobile: { display: 'flex', [theme.breakpoints.up('md')]: { display: 'none' } },
  listMobile: { margin: 0, paddingTop: 10, [theme.breakpoints.up('md')]: { margin: 20 } },
}))

export const List = (props) => {
  return (
    <AccountProvider>
      <Accounts {...props} />
    </AccountProvider>
  )
}

export const getType = (type, account) => {
  switch (type) {
    case 'artists':
      return (
        <Grid key={account._id} container item md={4} sm={6} xs={12} alignItems="center">
          <Artist account={account} />
        </Grid>
      )
    case 'labels':
      return (
        <Grid item xs={12}>
          <div>label</div>
        </Grid>
      )

    default:
      return (
        <Grid item xs={12}>
          <div>No artists found</div>
        </Grid>
      )
  }
}

export const Accounts = (props) => {
  const { state, accountActions, accountDispatch } = useAccountContext()
  let { pathname } = useLocation()
  const { accounts } = state
  pathname = pathname.substring(1)
  const classes = useStyles()

  const compare = (a, b) => {
    if (a.name < b.name) {
      return 1
    }
    if (a.name > b.name) {
      return -1
    }
    return 0
  }

  accounts.sort(compare)

  useEffect(() => {
    accountDispatch(accountActions.list(pathname))
  }, [accountDispatch, accountActions, pathname])

  return (
    <Box style={{ display: 'flex', alignContent: 'center', width: 1200, backgroundColor: '#121212' }}>
      <Box className={classes.sectionDesktop} style={{ minWidth: 200, flexDirection: 'column', backgroundColor: '#212121' }}>
        <Divider style={{ marginTop: 15, borderColor: 'transparent' }} />
        <Nav />
        <Divider style={{ marginTop: 10 }} />
        <TopRemixersList />
      </Box>
      <Box className={classes.listMobile} style={{  display: 'flex', alignContent: 'center', flexDirection: 'column', overflow: 'scroll' }}>
        <div style={{ display: 'flex', alignContent: 'center', width: '100%', padding: '1em', flexDirection: 'column' }}>
          <Typography style={{ textTransform: 'capitalize', fontWeight: '500', fontFamily: 'orbitron' }} variant="h4" component="h1">
            {pathname}
          </Typography>
          <br />
          <Grid container spacing={2}>
            {accounts?.map((account) => getType(pathname, account))}
          </Grid>
        </div>
      </Box>
    </Box>
  )
}

export default List
