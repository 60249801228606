import axios from 'axios'

export const types = {
  GENRES_LOADING: 'GENRES_LOADING',
  GENRES_LOADED: 'GENRES_LOADED',
  GENRES_LOAD_ERROR: 'GENRES_LOAD_ERROR',
}

export const list =
  () =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.SUBSCRIBER_LOADING, payload: true })
    const result = await axios.get(`/api/genres`)
    if (result.error) dispatch({ type: types.GENRES_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.GENRES_LOADED, payload: result.data })
  }
