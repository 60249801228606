import { useEffect, useState } from 'react'
import { UserProvider, useUserContext } from './context'
import { Grid, Card, Typography, TextField, Button, Box, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
const Joi = require('joi')

//TODO
// Avatar shows the image instead of the url
// Avatar change uploads image to digital ocean and overwrites users default image
// If user has not filled out given and family name when a new login is created,
// .. force them to go to the profile page to fill those out before they can use the website
// Once this is all ready, turn on the email/pw login and turn off the facebook login
// Form should be responsive
const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: 'red',
  },
  form: {
    padding: 20,
  },
}))

export const UserProfile = (props) => {
  return (
    <UserProvider>
      <ProfileForm {...props} />
    </UserProvider>
  )
}

const schema = Joi.object({
  id: Joi.alternatives(Joi.string(), Joi.object()),
  givenName: Joi.string().required(),
  familyName: Joi.string().required(),
  paypalEmail: Joi.string().allow(null, ''),
  alias: Joi.string().allow(null, ''),
}).options({ stripUnknown: true })

export const ProfileForm = ({ computedMatch, params, ...rest }) => {
  const { user, userActions, userDispatch } = useUserContext()
  // this sets default state to empty user object
  const [value, setValue] = useState(user)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    userDispatch(userActions.currentUser())
  }, [userDispatch, userActions])

  useEffect(() => {
    if (value.id === '') {
      setValue(user)
    }
  }, [value, user])

  const handleChange = (event) => {
    setValue({ ...value, [event.target.id]: event.target.value })
  }

  const submitForm = (event) => {
    event.preventDefault()
    const profileUpdate = {
      id: value.id,
      givenName: value.givenName,
      familyName: value.familyName,
      alias: value.alias,
      paypalEmail: value.paypalEmail,
    }
    const result = schema.validate(profileUpdate)
    setErrors(result.error ? { [result.error.details[0].context.key]: result.error } : {})
    userDispatch(userActions.save(profileUpdate))
  }

  const handleReset = (event) => {
    setValue(user)
    userDispatch(userActions.save(user))
  }

  const classes = useStyles({
    error: {
      color: 'red',
    },
  })

  return (
    <div
      className={classes.root}
      style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100vw', minHeight: '800px' }}
    >
      <Card
        variant="outlined"
        style={{
          backgroundColor: '#212121',
          display: 'flex',
          flexDirection: 'column',
          width: 1100,
          padding: '1em',
          color: 'white',
          position: 'absolute',
          top: 0,
        }}
      >
        &nbsp;
        <Typography variant="h5">MY PROFILE</Typography>
        <form className={classes.form} onSubmit={submitForm}>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextField
                sx={{ display: 'none' }}
                InputProps={{
                  readOnly: true,
                }}
                id="id"
                name="id"
                label="User ID"
                fullWidth
                variant="standard"
                value={value?.id || ''}
              />
              <TextField onChange={handleChange} value={value?.givenName || ''} id="givenName" name="givenName" label="First Name" fullWidth variant="standard" />
              {errors.givenName && <span className={classes.error}>"First Name" is required</span>}
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleChange} value={value?.familyName || ''} id="familyName" name="familyName" label="Last Name" fullWidth variant="standard" />
              {errors.familyName && <span className={classes.error}>"Last Name" is required</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                value={value?.paypalEmail || ''}
                name="paypalEmail"
                id="paypalEmail"
                label="Email for PayPal Payments"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField onChange={handleChange} value={value?.alias || ''} name="alias" id="alias" label="Artist Alias" fullWidth variant="standard" />
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Button fullWidth type="submit" variant="outlined">
            Save Changes
          </Button>
          <br />
          <br />
          <Box style={{ width: '100%', textAlign: 'center', fontSize: '14px' }}>
            <Link sx={{ cursor: 'pointer' }} onClick={handleReset}>
              RESET FORM
            </Link>
          </Box>
          <br />
        </form>
      </Card>
    </div>
  )
}

export default UserProfile
