import axios from 'axios'

export const types = {
  REMIXES_LOADING: 'REMIXES_LOADING',
  REMIXES_LOADED: 'REMIXES_LOADED',
  REMIXES_LOAD_ERROR: 'REMIXES_LOAD_ERROR',
  REMIX_LOADING: 'REMIX_LOADING',
  REMIX_LOADED: 'REMIX_LOADED',
  REMIX_LOAD_ERROR: 'REMIX_LOAD_ERROR',
}

export const list =
  () =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.REMIXES_LOADING, payload: true })
    const result = await axios.get(`/api/remixes`)
    if (result.error) dispatch({ type: types.REMIXES_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.REMIXES_LOADED, payload: result.data })
  }

export const listByProjectSlug =
  (slug) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.REMIXES_LOADING, payload: true })
    const result = await axios.get(`/api/remixes/project/${slug}`)
    if (result.error) dispatch({ type: types.REMIXES_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.REMIXES_LOADED, payload: result.data })
  }

export const listByAvgRatings =
  () =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.REMIXES_LOADING, payload: true })
    const result = await axios.get(`/api/remixes/avg-ratings`)
    if (result.error) dispatch({ type: types.REMIXES_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.REMIXES_LOADED, payload: result.data })
  }

export const load =
  (_id) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.REMIX_LOADING, payload: true })
    const result = await axios.get(`/api/remixes/${_id}`)
    if (result.error) dispatch({ type: types.REMIX_LOAD_ERROR, payload: { error: result.error } })

    dispatch({ type: types.REMIX_LOADED, payload: result.data })
  }
