import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import * as Joi from 'joi'
import AccountSelectControl from '../accounts/account-select'
import { ProjectProvider, useProjectContext } from './context'
import { Card, Typography, TextField, Button, Box, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: 'red',
  },
  form: {
    padding: 20,
  },
}))

export const ProjectCreate = (props) => {
  return (
    <ProjectProvider>
      <CreateForm {...props} />
    </ProjectProvider>
  )
}

const schema = Joi.object({
  title: Joi.string().required(),
  tag1: Joi.string().required(),
  tag2: Joi.string().required(),
  tag3: Joi.string(),
  tag4: Joi.string(),
  subTitle: Joi.string().required(),
  summary: Joi.string().required(),
  content: Joi.string().required(),
  imageTitle: Joi.string().required(),
  imageSource: Joi.string().required(),
  demoTrack: Joi.string().required(),
  stems: Joi.string().required(),
})

export const CreateForm = ({ computedMatch, params, ...rest }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: { tag3: 'BPM: ', tag4: 'KEY: ' },
  })

  const { projectActions, projectDispatch } = useProjectContext()

  const submitForm = (data) => {
    const accountId = document.getElementById('select-account-value')

    const newProject = {
      title: data.title,
      artist: {
        _id: accountId.value,
      },
      tags: [data.tag1, data.tag2, data.tag3, data.tag4],
      details: {
        subTitle: data.subTitle,
        summary: data.summary,
        content: data.content,
        image: {
          title: data.imageTitle,
          src: data.imageSource,
        },
        demoTrack: data.demoTrack,
        stems: data.stems,
      },
    }
    projectDispatch(projectActions.create(newProject))
    reset()
  }

  const classes = useStyles()

  return (
    <div className={classes.root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100vw' }}>
      <Card
        variant="outlined"
        style={{
          backgroundColor: '#212121',
          display: 'flex',
          flexDirection: 'column',
          width: 1100,
          padding: '1em',
          color: 'white',
        }}
      >
        &nbsp;
        <Typography variant="h5">CREATE A NEW PROJECT</Typography>
        <form className={classes.form} onSubmit={handleSubmit(submitForm)}>
          <TextField name="title" label="Project Title" fullWidth variant="standard" {...register('title')} />
          {errors.title && <p className={classes.error}>"Project Title" is required</p>}
          <br />
          <br />
          <br />
          <TextField name="subTitle" label="Project Subtitle" rows={1} fullWidth placeholder="One sentence" multiline {...register('subTitle')} />
          {errors.subTitle && <p className={classes.error}>"Project Subtitle" is required</p>}
          <br />
          <br />
          <TextField name="summary" label="Summary" rows={2} fullWidth placeholder="A few sentences" multiline {...register('summary')} />
          {errors.summary && <p className={classes.error}>"Summary" is required</p>}
          <br />
          <br />
          <TextField name="content" label="Description" rows={4} fullWidth placeholder="A paragraph or two" multiline {...register('content')} />
          {errors.content && <p className={classes.error}>"Description" is required</p>}
          <br />
          <br />
          <AccountSelectControl name="artistId" label="Artist" required fullWidth variant="standard" />
          <br />
          <br />
          <TextField name="tag1" label="Primary Music Genre" fullWidth variant="standard" {...register('tag1')} />
          {errors.tag1 && <p className={classes.error}>"Primary Music Genre" is required</p>}
          <br />
          <br />
          <TextField name="tag2" label="Secondary Music Genre" fullWidth variant="standard" {...register('tag2')} />
          {errors.tag2 && <p className={classes.error}>"Secondary Music Genre" is required</p>}
          <br />
          <br />
          <TextField name="tag3" label="BPM" fullWidth variant="standard" {...register('tag3')} />
          {errors.tag3 && <p className={classes.error}>"BPM" is required</p>}
          <br />
          <br />
          <TextField name="tag4" label="KEY" fullWidth variant="standard" {...register('tag4')} />
          {errors.tag4 && <p className={classes.error}>"KEY" is required</p>}
          <br />
          <br />
          <TextField name="imageTitle" label="Image Title" fullWidth variant="standard" {...register('imageTitle')} />
          {errors.imageTitle && <p className={classes.error}>"Image Title" is required</p>}
          <br />
          <TextField name="imageSource" label="Image Source" fullWidth variant="standard" {...register('imageSource')} />
          {errors.imageSource && <p className={classes.error}>"Image Source" is required</p>}
          <br />
          <br />
          <TextField name="demoTrack" label="Demo Track" fullWidth variant="standard" {...register('demoTrack')} />
          {errors.demoTrack && <p className={classes.error}>"Demo Track" is required</p>}
          <br />
          <br />
          <TextField name="stems" label="Stems" fullWidth variant="standard" {...register('stems')} />
          {errors.stems && <p className={classes.error}>"Stems" are required</p>}
          <br />
          <br />
          <br />
          <Button fullWidth type="submit" variant="outlined">
            Submit
          </Button>
          <br />
          <br />
          <Box style={{ width: '100%', textAlign: 'center', fontSize: '14px' }}>
            <Link onClick={() => reset()}>RESET FORM</Link>
          </Box>
          <br />
          <br />
        </form>
      </Card>
    </div>
  )
}

export default ProjectCreate
