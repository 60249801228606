import { createTheme } from '@mui/material/styles'
import { amber } from '@mui/material/colors'

const medAmber = amber[500]
const medOrange = '#ffc107'
const medDeepPurple = '#c207ff'

export default createTheme({
  palette: {
    common: {
      mediumAmber: medAmber,
      orange: medOrange,
    },
    mode: 'dark',
    primary: {
      main: medAmber,
    },
    secondary: {
      main: medDeepPurple,
    },
  },
  typography: {
    p: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
  },
  status: { danger: medOrange },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: 'red',
  //         fontSize: '1rem',
  //       },
  //     },
  //   },
  // },
})
