import moment from 'moment'
import { useEffect, useState } from 'react'
import { UserProvider, useUserContext } from './context'
import { Checkbox, Stack, Switch, Avatar, Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { AcceptedEmailButton } from '../email'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export const UserInvitationRequests = (props) => {
  return (
    <UserProvider>
      <InvitationRequestList {...props} />
    </UserProvider>
  )
}

export const InvitationRequestList = ({ computedMatch, params, ...rest }) => {
  const { user, userActions, userDispatch } = useUserContext()
  const classes = useStyles()
  const [checked, setChecked] = useState([])

  useEffect(() => {
    userDispatch(userActions.list())
  }, [userDispatch, userActions])

  const handleCheckChange = async (_id) => {
    let filteredChecklist = []
    if (checked.includes(_id)) {
      filteredChecklist = checked.filter(function (checkedRowId) {
        return checkedRowId !== _id
      })
      setChecked(filteredChecklist)
    } else {
      setChecked((checked) => [...checked, _id])
    }
  }

  const handleAccepted = async (_id) => {
    await userDispatch(userActions.accept(_id))
    await userDispatch(userActions.list())
  }

  return (
    <div className={classes.root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100vw' }}>
      <Grid container>
        <Grid item xs={12}>
          <IconButton />
        </Grid>
      </Grid>
      <TableContainer component={Paper} align="center" sx={{ width: '80%' }}>
        <Table stickyHeader sx={{ minWidth: 1100, width: '90%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="right" colSpan={8}>
                <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="flex-end">
                  <AcceptedEmailButton sendToList={checked} btnLabel="Accepted" variant="outlined" />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">Avatar</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Alias</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">PayPal</TableCell>
              <TableCell align="center">Last Auth</TableCell>
              <TableCell align="center">Accepted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user?.users?.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">
                  <Checkbox onChange={() => handleCheckChange(row._id)} />
                </TableCell>
                <TableCell align="center">
                  <Avatar alt={row._id} src={row.avatar} />
                </TableCell>
                <TableCell align="center">
                  {row.familyName}, {row.givenName}
                </TableCell>
                <TableCell align="center">{row.alias}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.paypalEmail}</TableCell>
                <TableCell align="center">{moment(row.lastAuth).fromNow()}</TableCell>
                <TableCell align="center">
                  <Switch onChange={() => handleAccepted(row._id)} disabled={user.id === row._id} checked={row.accepted} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </div>
  )
}

export default UserInvitationRequests
