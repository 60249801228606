import { useEffect } from 'react'
import { Tooltip, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import InfoIcon from '@mui/icons-material/Info'
import { RemixProvider, useRemixContext } from '../context'

export const TopRemixersList = (props) => {
  return (
    <RemixProvider>
      <TopRemixers {...props} />
    </RemixProvider>
  )
}

export const TopRemixers = () => {
  const { state, remixActions, remixDispatch } = useRemixContext()
  const { remixes } = state

  function compare(a, b) {
    const remixerA = (a.avg_rating * a.sum_rating).toFixed(1)
    const remixerB = (b.avg_rating * b.sum_rating).toFixed(1)

    let comparison = parseInt(0)
    if (parseFloat(remixerA) < parseFloat(remixerB)) {
      comparison = parseInt(1)
    } else if (parseFloat(remixerA) > parseFloat(remixerB)) {
      comparison = parseInt(-1)
    }
    return comparison
  }

  remixes.sort(compare)

  useEffect(() => {
    remixDispatch(remixActions.listByAvgRatings())
  }, [remixDispatch, remixActions])

  return (
    <Box style={{ width: '100%', maxWidth: 360 }}>
      <List>
        <ListItem>
          <ListItemText
            style={{ marginBottom: 0 }}
            primary={
              <Box>
                <span
                  style={{
                    textTransform: 'uppercase',
                    marginBottom: 0,
                    marginTop: 10,
                    color: 'rgb(153, 153, 153)',
                    fontWeight: 'bold',
                    fontFamily: 'lato-regular',
                    fontSize: '0.72em',
                  }}
                >
                  Top 10 Remixers&nbsp;
                  <Tooltip title="Score Calculation: Sum of Ratings * Avg Ratings">
                    <InfoIcon style={{ width: 12, height: 12 }} />
                  </Tooltip>
                </span>
              </Box>
            }
            primaryTypographyProps={{
              fontSize: 20,
              fontWeight: 'medium',
              letterSpacing: 0,
            }}
          />
        </ListItem>
        {remixes.slice(0, 10)?.map((remix, i) => (
          <ListItem key={i}>
            <ListItemAvatar>
              <Avatar style={{ width: 40, height: 40 }} alt={`${remix.remixer_givenName} ${remix.remixer_familyName}`} src={remix.remixer_avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={remix.remixer_alias ? remix.remixer_alias : `${remix.remixer_givenName} ${remix.remixer_familyName}`}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: '530',
                textOverflow: 'ellipses',
              }}
              secondary={
                <span>
                  <StarIcon style={{ width: 12, height: 12 }} />
                  {(remix.avg_rating * remix.sum_rating).toFixed(1)}
                </span>
              }
              secondaryTypographyProps={{
                fontSize: 14,
                color: '#848484',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default TopRemixers
