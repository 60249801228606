import { Avatar, Button, IconButton, Divider } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { Notifications } from '@mui/icons-material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MoreIcon from '@mui/icons-material/MoreVert'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useUserContext } from '../users/context'
import { NotificationsMenu } from '../notifications/list'

const useStyles = makeStyles((theme, auth) => ({
  grow: { flexGrow: 1 },
  menuButton: { marginRight: theme.spacing(2) },
  title: { display: 'block', color: theme.palette.primary.main, textDecoration: 'none' },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': { backgroundColor: alpha(theme.palette.common.white, 0.25) },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: { color: 'inherit' },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: { width: '20ch' },
  },
  sectionDesktop: { display: 'none', [theme.breakpoints.up('md')]: { display: 'flex' } },
  sectionNotifications: { display: 'flex', [theme.breakpoints.up('md')]: { display: 'flex' } },
  sectionMobile: { display: 'flex', [theme.breakpoints.up('md')]: { display: 'none' } },
  small: { width: 26, height: 26 },
}))

export default function Header() {
  const { user, userActions, userDispatch } = useUserContext()
  const classes = useStyles(user)
  const [anchorEl, setAnchorEl] = useState(null)
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const [notificationsCount, setNotificationsCount] = useState(0)

  const isMenuOpen = Boolean(anchorEl)
  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const getNotificationsCount = (count) => {
    setNotificationsCount(count)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationsMenuOpen = (event) => {
    setNotificationsAnchorEl(event.currentTarget)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleNotificationsMenuClose = () => {
    setNotificationsAnchorEl(null)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setNotificationsAnchorEl(null)
    setMobileMoreAnchorEl(null)
  }

  const handleLogOut = (event) => {
    userDispatch(userActions.logout())
    handleMenuClose()
  }

  const notificationsMenuId = 'primary-search-notifications-menu'
  const renderNotificationsMenu = (
    <NotificationsMenu
      notificationsMenuId={notificationsMenuId}
      notificationsAnchorEl={notificationsAnchorEl}
      isNotificationsMenuOpen={isNotificationsMenuOpen}
      handleNotificationsMenuClose={handleNotificationsMenuClose}
      getNotificationsCount={getNotificationsCount}
    />
  )

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      id={menuId}
      open={isMenuOpen}
      placement="bottom-start"
      onClose={handleMenuClose}
    >
      {user.role === 'admin' ? (
        <span>
          <MenuItem style={{ color: 'white' }} component={Link} to="/admin">
            Admin
          </MenuItem>
          <Divider style={{ display: !user.accepted ? 'none' : 'flex' }} />
        </span>
      ) : null}
      <MenuItem style={{ color: 'white' }} component={Link} to="/profile">
        Profile
      </MenuItem>
      <Divider style={{ display: !user.accepted ? 'none' : 'flex' }} />
      <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderAuthMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
    </Menu>
  )

  return (
    <div>
      <AppBar color="default" position="fixed">
        <Toolbar style={{ minHeight: 46, margin: 5 }}>
          <Link style={{ textDecoration: 'none' }} to={'/'}>
            <Typography style={{ fontFamily: 'Orbitron' }} className={classes.title} variant="h5" component="h1" noWrap>
              ODDE0TRAX
            </Typography>
          </Link>
          <div className={classes.grow} />
          {user.authenticated ? (
            <>
              <div className={classes.sectionNotifications}>
                <div style={{ position: 'relative' }}>
                  <IconButton
                    sx={{ width: 50, height: 50, margin: 0, position: 'relative' }}
                    edge="end"
                    aria-label="notifications for current user"
                    aria-controls={notificationsMenuId}
                    aria-haspopup="true"
                    onClick={handleNotificationsMenuOpen}
                  >
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        display: `${notificationsCount > 0 ? 'inline' : 'none'}`,
                        position: 'absolute',
                        right: 10,
                        top: 14,
                        zIndex: 10,
                        borderRadius: 50,
                        backgroundColor: '#d23f31',
                        border: '1px solid black',
                        fontSize: '0.3em',
                        lineHeight: 1.9,
                        fontWeight: 'bolder',
                      }}
                    >
                      {notificationsCount > 0 ? notificationsCount : ''}
                    </div>
                    {notificationsCount > 0 ? <Notifications /> : <NotificationsNoneIcon />}
                  </IconButton>
                </div>
              </div>
              <div className={classes.sectionDesktop}>
                <IconButton
                  sx={{ width: 50, height: 50, margin: 0 }}
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                >
                  <Avatar src={user?.avatar} className={classes.small} />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                  <MoreIcon />
                </IconButton>
              </div>
            </>
          ) : (
            <div>
              <Button to="/signin" style={{ color: '#ffc107' }} component={Link} size="large" variant="text">
                Sign In / Up
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
      {user.authenticated ? renderAuthMobileMenu : null}
      {user.authenticated ? renderNotificationsMenu : null}
    </div>
  )
}
