import axios from 'axios'

export const types = {
  NOTIFICATION_LOADING: 'NOTIFICATION_LOADING',
  NOTIFICATION_LOADED: 'NOTIFICATION_LOADED',
  NOTIFICATION_LOAD_ERROR: 'NOTIFICATION_LOAD_ERROR',

  NOTIFICATIONS_LOADING: 'NOTIFICATIONS_LOADING',
  NOTIFICATIONS_LOADED: 'NOTIFICATIONS_LOADED',
  NOTIFICATIONS_LOAD_ERROR: 'NOTIFICATIONS_LOAD_ERROR',

  NOTIFICATIONS_CLEARING: 'NOTIFICATIONS_CLEARING',
  NOTIFICATIONS_CLEARED: 'NOTIFICATIONS_CLEARED',
  NOTIFICATIONS_CLEAR_ERROR: 'NOTIFICATIONS_CLEAR_ERROR',
}

export const list =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.NOTIFICATIONS_LOADING, payload: true })
    const result = await axios.get(`/api/notifications`)
    if (result.error) dispatch({ type: types.NOTIFICATIONS_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.NOTIFICATIONS_LOADED, payload: result.data })
  }

export const load =
  (_id) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.NOTIFICATION_LOADING, payload: true })
    const result = await axios.get(`/api/notifications/${_id}`)
    if (result.error) dispatch({ type: types.NOTIFICATION_LOAD_ERROR, payload: { error: result.error } })

    dispatch({ type: types.NOTIFICATION_LOADED, payload: result.data })
  }

export const clear =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.NOTIFICATIONS_CLEARING, payload: true })
    const result = await axios.post(`/api/notifications/clear`)
    if (result.error) dispatch({ type: types.NOTIFICATIONS_CLEAR_ERROR, payload: { error: result.error } })
    dispatch({ type: types.NOTIFICATIONS_CLEARED, payload: [] })
  }
