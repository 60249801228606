export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'NOTIFICATIONS_LOADED':
      return { ...state, notifications: payload, loading: false }
    case 'NOTIFICATION_LOADED':
      return { ...state, notification: payload, loading: false }
    case 'NOTIFICATIONS_CLEARED':
      return { ...state, notifications: payload, loading: false }

    default:
      return state
  }
}

export default reducer
