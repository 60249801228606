import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_ID,
  authDomain: 'oddeotrax-314004.firebaseapp.com',
  projectId: 'oddeotrax-314004',
  storageBucket: 'oddeotrax-314004.appspot.com',
  messagingSenderId: '838509595656',
  appId: '1:838509595656:web:4899ded13fe639f528d5bd',
  measurementId: 'G-Q7H47EE1DP',
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
