import Markup from 'interweave'
import { Grid, Divider, Avatar, Chip, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import MiniAudioPlayer from '../../mini-audio-player'

export const ProjectHeading = ({ currentSong, playing, playPause, project }) => {
  const { title, artist, tags, details } = project
  return (
    <Card style={{ width: '100%', margin: '0px 1px 20px 1px' }}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <CardContent sx={{ position: 'relative' }}>
            <CardMedia component="img" image={details.image.src} alt={title} />
            <div
              style={{
                padding: 30,
                border: '2px solid rgba(255,193,7,0.9)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(18,18,18,0.2)',
                borderRadius: '50%',
                backdropFilter: 'blur(10px)',
              }}
            >
              <MiniAudioPlayer size={50} color={'#ffc107'} left={6} playPause={playPause} currentSong={currentSong} song={0} playing={playing} />
            </div>
          </CardContent>
        </Grid>
        <Grid item md={6} xs={12}>
          <CardHeader
            avatar={<Avatar alt={artist.name} src={artist.image} sx={{ width: 56, height: 56 }} />}
            title={<span style={{ fontWeight: '500', color: '#fff', fontFamily: 'lato-regular', fontSize: '1.5rem' }}>{title}</span>}
            subheader={<span style={{ fontWeight: '300', fontFamily: 'lato-light', fontSize: '1.3rem' }}>by {artist.name}</span>}
          />
          <CardContent>
            <Divider style={{ marginTop: '-22px' }} />
            <br />
            {tags?.map((tag, i) => (
              <Chip style={{ marginRight: 5 }} key={i} color="primary" size="large" variant="outlined" label={tag} />
            ))}
            <br />
            <br />
            <Typography variant="h6">Project Details</Typography>
            <p style={{ fontSize: '1rem', lineHeight: '1.5', color: '#fff' }}>
              <Markup content={details.summary} />
            </p>
            <p style={{ fontSize: '1rem', lineHeight: '1.5', color: '#fff' }}>
              <Markup content={details.content} />
            </p>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ProjectHeading
