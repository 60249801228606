import moment from 'moment'
import { useEffect, useState } from 'react'
import { NotificationsProvider, useNotificationsContext } from './context'
import { IconButton, Grid, Tooltip, Menu, MenuItem, Card, CardActionArea, CardHeader } from '@mui/material'
import { Star, Done, ClearAll } from '@mui/icons-material'

export const NotificationsMenu = (props) => {
  return (
    <NotificationsProvider>
      <Notifications {...props} />
    </NotificationsProvider>
  )
}

export const Notifications = ({ getNotificationsCount, notificationsAnchorEl, notificationsMenuId, isNotificationsMenuOpen, handleNotificationsMenuClose, ...props }) => {
  const { state, notificationsActions, notificationsDispatch } = useNotificationsContext()
  const { notifications } = state
  const [notificationsCount, setNotificationsCount] = useState(0)

  useEffect(() => {
    notificationsDispatch(notificationsActions.list())
  }, [notificationsDispatch, notificationsActions])

  useEffect(() => {
    getNotificationsCount(notifications.length)
    setNotificationsCount(notifications.length)
  }, [getNotificationsCount, notifications])

  const handleClearAll = () => {
    notificationsDispatch(notificationsActions.clear())
  }

  return (
    <Menu
      anchorEl={notificationsAnchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      id={notificationsMenuId}
      keepMounted
      open={isNotificationsMenuOpen}
      onClose={handleNotificationsMenuClose}
      sx={{ margin: 0, padding: 0 }}
    >
      <div style={{ minWidth: 300, width: '100%' }}>
        <Grid container>
          <Grid item xs={8}>
            <div onClick={handleNotificationsMenuClose} style={{ marginLeft: 10, fontFamily: 'lato', lineHeight: 2.2, cursor: 'pointer' }}>
              Notifications
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Tooltip title="Clear All">
              <span>
                <IconButton onClick={handleClearAll} disabled={notificationsCount > 0 ? false : true} sx={{ marginRight: 0 }}>
                  <ClearAll sx={{ width: 17, height: 17 }} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
      {notifications.length > 0 ? (
        notifications.map((n, i) => (
          <MenuItem key={i} divider sx={{ padding: 0, margin: '0.1em' }}>
            <Card raised sx={{ minWidth: 100, width: '100%' }}>
              <CardActionArea>
                <CardHeader
                  avatar={
                    <div style={{ minHeight: '50px', height: '100%' }}>
                      <div style={{ border: 'solid 1px #ffc107', borderRadius: '50%', width: 40, height: 40, position: 'relative' }}>
                        <Star sx={{ width: 20, height: 20, color: '#ffc107', position: 'absolute', left: 9, top: 8 }} />
                      </div>
                    </div>
                  }
                  title={n.title}
                  subheader={
                    <>
                      {n.details.subheading}
                      <br />
                      {n.details.content}
                      <br />
                      <span style={{ fontSize: '0.8em' }}>{moment(n.details.pubDate).fromNow()}</span>
                    </>
                  }
                />
              </CardActionArea>
            </Card>
          </MenuItem>
        ))
      ) : (
        <MenuItem divider sx={{ padding: 0, margin: '0.1em' }}>
          <Card raised sx={{ minWidth: 100, width: '100%' }}>
            <CardHeader
              avatar={
                <div style={{ minHeight: '10px', height: '100%' }}>
                  <div style={{ border: 'solid 1px #ffc107', borderRadius: '50%', width: 40, height: 40, position: 'relative' }}>
                    <Done sx={{ width: 20, height: 20, color: '#ffc107', position: 'absolute', left: 9, top: 8 }} />
                  </div>
                </div>
              }
              title="Nothing New"
              subheader="All caught up!"
            />
          </Card>
        </MenuItem>
      )}
    </Menu>
  )
}

export default NotificationsMenu
