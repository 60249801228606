import { useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import AlbumIcon from '@mui/icons-material/Album'
import PlayForWorkIcon from '@mui/icons-material/PlayForWork'
import PeopleIcon from '@mui/icons-material/People'
import { Feed } from '@mui/icons-material'
import { Card, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'
import log from 'loglevel'

export const ListItemLink = (props) => <ListItem button component={Link} {...props} />

const useStyles = makeStyles({
  root: {
    '& .MuiListItemIcon-root': {
      color: '#fff',
    },
    '& .MuiListItemText-root': {
      color: '#fff',
    },
  },
  selected: {
    '&$selected': {
      '& .MuiListItemIcon-root': {
        color: '#ffc107',
      },
      '& .MuiListItemText-root': {
        color: '#ffc107',
      },
    },
  },
})

export const Nav = (props) => {
  let { pathname } = useLocation()
  pathname = pathname.substring(1)
  const classes = useStyles()
  log.debug(pathname)
  return (
    <Card style={{ boxShadow: 'none' }}>
      <List dense style={{ backgroundColor: '#212121', color: '#fff' }}>
        <ListItemLink classes={{ selected: classes.selected, root: classes.root }} selected={pathname === '' ? true : false} to="/">
          <ListItemIcon>
            <Feed size="lg" />
          </ListItemIcon>
          <ListItemText>Feed</ListItemText>
        </ListItemLink>
        <ListItemLink classes={{ selected: classes.selected, root: classes.root }} selected={pathname === 'projects' ? true : false} to="/projects">
          <ListItemIcon>
            <PlayForWorkIcon size="lg" />
          </ListItemIcon>
          <ListItemText>Projects</ListItemText>
        </ListItemLink>
        <ListItemLink classes={{ selected: classes.selected, root: classes.root }} selected={pathname === 'artists' ? true : false} to="/artists">
          <ListItemIcon>
            <PeopleIcon size="lg" />
          </ListItemIcon>
          <ListItemText>Artists</ListItemText>
        </ListItemLink>
        <ListItemLink classes={{ selected: classes.selected, root: classes.root }} selected={pathname === 'releases' ? true : false} to="/releases">
          <ListItemIcon>
            <AlbumIcon size="lg" />
          </ListItemIcon>
          <ListItemText>Releases</ListItemText>
        </ListItemLink>
      </List>
    </Card>
  )
}

export default Nav
