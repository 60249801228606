import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, TextField } from '@mui/material'
import { NotificationAdd } from '@mui/icons-material'
import { useUserContext } from '../users/context'
import { SubscriberProvider, useSubscriberContext } from './context'

const useStyles = makeStyles((theme) => ({
  email_container: {
    width: '50%',
    marginBottom: 20,
    margin: '0px auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
  email_input: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginBottom: 10,
    },
  },
  cta_button: {
    '&.MuiButton-root': {
      color: '#ffc107',
      borderColor: '#ffc107',
    },
    '&.MuiButton-root:hover': {
      color: '#ffc107',
    },
  },
}))

export const Subscribe = (props) => {
  return (
    <SubscriberProvider>
      <SubscriberControl {...props} />
    </SubscriberProvider>
  )
}

export const SubscriberControl = (props) => {
  const { subscriberActions, subscriberDispatch } = useSubscriberContext()
  const { user } = useUserContext()
  const [error, setError] = useState(false)
  const [loggedIn] = useState(user.authenticated && user.accepted)
  const [helperText, setHelperText] = useState('')
  const emailLabel = 'Email Address'

  const classes = useStyles()

  const emailSubscriptionHandler = () => {
    let subscriber = {}
    let email_field = ''
    if (loggedIn) {
      subscriber = { email: user.email, form: props.form, tags: props.tags }
      subscriberDispatch(subscriberActions.subscribe(subscriber))
      setError(false)
      setHelperText('Success! Please respond to the confirmation email')
    } else {
      email_field = document.getElementById('email-input')
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email_field.value)) {
        const subscriber = { email: email_field.value, form: props.form, tags: props.tags }
        subscriberDispatch(subscriberActions.subscribe(subscriber))
        setError(false)
        setHelperText('Success! Please respond to the confirmation email')
      } else {
        setError(true)
        setHelperText('A valid email address is required')
      }
    }
  }

  return (
    <div className={classes.email_container}>
      {!loggedIn ? <TextField fullWidth error={error} helperText={helperText} id="email-input" className={classes.email_input} label={emailLabel} variant="outlined" /> : null}
      <Button startIcon={<NotificationAdd />} variant="standard" onClick={emailSubscriptionHandler} className={classes.cta_button}>
        SEND ME UPDATES
      </Button>
    </div>
  )
}

export default Subscribe
