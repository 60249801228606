import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: true,
  release: {
    _id: '',
    title: '',
    artist: {},
    image: {},
    type: '',
    tags: [],
    slug: '',
    media: {},
    shops: [],
    copyright: {},
  },
  releases: [],
}

const ReleaseContext = createContext()

export const ReleaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const releaseDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.releases = state
  return <ReleaseContext.Provider value={{ state, releaseActions: actions, releaseDispatch }}>{children}</ReleaseContext.Provider>
}

export const useReleaseContext = () => {
  const context = useContext(ReleaseContext)
  if (context === undefined) throw new Error('useReleaseContext must be used within an ReleaseProvider')
  return context
}
