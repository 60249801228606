import React, { createContext, useContext, useReducer, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import reducer from './reducers'
import * as actions from './actions'
import { auth } from '../firebase'
import axios from 'axios'
import log from 'loglevel'

const initalState = {
  id: '',
  name: '',
  avatar: '',
  alias: '',
  email: '',
  paypalEmail: '',
  loading: true,
  authenticated: false,
  accepted: false,
  role: '',
  error: '',
  users: [],
}

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const userDispatch = useCallback((fn) => fn({ dispatch, history }), [history])
  const [firebaseUser, setFirebaseUser] = useState(null)
  const [tkn, setTkn] = useState(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      firebaseUser ? setFirebaseUser(firebaseUser) : userDispatch(actions.unauthenticated())
    })
    return unsubscribe
  }, [userDispatch])

  useEffect(() => {
    if (firebaseUser) {
      const reqsIntId = axios.interceptors.request.use(async (config) => {
        const token = await firebaseUser?.getIdToken()
        if (log.getLevel(log.levels.DEBUG)) {
          if (token !== tkn) {
            log.debug('user token', tkn)
            setTkn(token)
          }
        }
        config.headers.authorization = `Bearer ${token}`
        return config
      })
      return () => axios.interceptors.request.eject(reqsIntId)
    }
  }, [firebaseUser, userDispatch, tkn])

  useEffect(() => {
    firebaseUser ? userDispatch(actions.currentUser()) : userDispatch(actions.unauthenticated)
  }, [firebaseUser, userDispatch])

  window.reactState = window.reactState || {}
  window.reactState.user = state
  return <UserContext.Provider value={{ user: state, userActions: actions, userDispatch }}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (context === undefined) throw new Error('useUserContext must be used within an UserProvider')
  return context
}
