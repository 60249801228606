import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Grid, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PaidIcon from '@mui/icons-material/Paid'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const projects = [
  {
    id: 'bad-things-part-1',
    slug: 'bad-things-part-1',
    name: 'bad-things-part-1 cover',
    title: 'Remix Tracks',
    sub_title: 'Cash + Royalties',
    description: 'Remix original tracks for Singles and EPs that are distributed globally',
    avatarImg: '/img/oddeo_logo.svg',
    img: 'https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/bad-things-1/BadThingsCover.webp',
  },
  {
    id: 'ec:v2.0',
    slug: 'ec:v2.0',
    title: 'Produce Tracks',
    sub_title: 'Limited Licensing',
    description: 'Produce original tracks for music compilations and curated playlists',
    name: 'empathy-circuit cover',
    avatarImg: '/img/oddeo_logo.svg',
    img: 'https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/empathy-circuit-2/empathy_circuit_cover.webp',
  },
]

export const ProjectItem = ({ slug, name, description, img, avatarImg }) => {
  const classes = useStyles()
  return (
    <Card className={classes.root} variant="outlined" component={Link} to={`./${slug}` || ''}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" src={avatarImg} className={classes.avatar}>
            D
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={name}
        subheader="oddeo netwerx"
      />
      <CardMedia className={classes.media} image={img} title={name} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Chip variant="outlined" size="small" label="Release" />
      </CardActions>
    </Card>
  )
}

export const Home = (props) => {
  const classes = useStyles()
  return (
    <div
      style={{
        flex: 'flex',
        width: '100vw',
        height: '100%',
        backgroundColor: '#fff',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Independent artists get paid to collaborate on electronic music" />
        <title>ODDEOTRAX | Time For a Remix</title>
      </Helmet>
      <div className={classes.heroImage}>
        <Grid container className={classes.heroText}>
          <Grid item xs={12}>
            <h1 className={classes.mainheading}>Time For a Remix</h1>
            <h2 className={classes.subheading}>Get paid to collaborate on electronic music</h2>
          </Grid>
          <Grid item xs={12} className={classes.downArrow}>
            <br />
            <br />
            <Button to="/request" style={{ color: '#ffc107', minWidth: '25%' }} component={Link} size="large" variant="outlined" color="primary">
              Request Invitation
            </Button>
            <br />
            <br />
            <br />
            <ExpandMoreIcon fontSize="large" />
          </Grid>
        </Grid>
      </div>
      <div style={{ color: '#424242', display: 'flex', alignItems: 'center', padding: '2em', flexDirection: 'column', textAlign: 'center' }}>
        <h2 style={{ fontFamily: 'Orbitron', fontSize: 30, margin: 20, fontWeight: 500 }}>WHAT IS ODDE0TRAX?</h2>
        <Typography style={{ fontSize: '1.3rem', fontFamily: 'lato-light', fontWeight: 300 }} variant="h5" component="h3">
          A collaborative freelance marketplace for <br /> independent electronic musicians
        </Typography>
      </div>
      <Grid container justifyContent="center" spacing={0} style={{ maxWidth: 1200, margin: '0 auto' }}>
        <video poster="https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/images/new_to_you_640x480.webp" style={{ maxWidth: '100%', height: 'auto' }} controls>
          <source src="/video/oddeotrax_main_5232021.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Grid>
      <Grid container justifyContent="center" spacing={0} style={{ maxWidth: 1200, margin: '0 auto', padding: '35px 0px 60px 0px' }}>
        <Grid item xs={12} md={4} style={{ border: 'solid 5px' }}>
          <Card style={{ padding: '1em', color: '#424242', height: '100%', backgroundColor: '#fff' }}>
            <Typography variant="h5" component="h3" style={{ fontFamily: 'lato-regular', fontWeight: 300 }}>
              <span style={{ color: '#ffc107' }}>
                <HeadphonesIcon fontSize="medium" /> Review Gigs
              </span>
            </Typography>
            <Typography style={{ fontSize: '1.3rem', fontFamily: 'lato-light', fontWeight: 300 }}>
              Paying gigs are posted by music labels, independent musicians, playlist curators, and DJs
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} style={{ border: 'solid 5px' }}>
          <Card style={{ padding: '1em', color: '#424242', height: '100%', backgroundColor: '#fff' }}>
            <Typography variant="h5" component="h3" style={{ fontFamily: 'lato-regular', fontWeight: 300 }}>
              <span style={{ color: '#ffc107' }}>
                <EqualizerIcon fontSize="medium" /> Remix Tracks
              </span>
            </Typography>
            <Typography style={{ fontSize: '1.3rem', fontFamily: 'lato-light', fontWeight: 300 }}>You download stems, work your magic, and submit your track for review</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} style={{ border: 'solid 5px' }}>
          <Card style={{ padding: '1em', color: '#424242', height: '100%', backgroundColor: '#fff' }}>
            <Typography variant="h5" component="h3" style={{ fontFamily: 'lato-regular', fontWeight: 300 }}>
              <span style={{ color: '#ffc107' }}>
                <PaidIcon fontSize="medium" /> Get Paid
              </span>
            </Typography>
            <Typography style={{ fontSize: '1.3rem', fontFamily: 'lato-light', fontWeight: 300 }}>
              We streamline the process so you can stay focused on creating new music
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: '100vw', margin: '0 auto' }}>
        <div className={classes.socialImage}>
          <h2 style={{ fontSize: 30, margin: '20px 0px 60px 0px', fontWeight: 400 }}>
            <span style={{ fontFamily: 'Orbitron', fontWeight: 'bold' }}>GIGS</span>
          </h2>

          <Grid container justifyContent="center" spacing={2}>
            {projects.map((p) => (
              <Grid key={p.id} item xs={12} md={6} style={{ maxWidth: 600 }}>
                <Card key={p.id} style={{ padding: '0em', flexBasis: '33%', color: '#fff', margin: '0 auto', backgroundColor: '#212121' }}>
                  <img width="100%" src={p.img} alt={p.name} />
                  <Typography variant="h5" component="h3" style={{ fontFamily: 'lato-regular', fontWeight: 300 }}>
                    {p.title}
                  </Typography>
                  <Typography style={{ fontSize: '1.3rem', margin: 20, fontFamily: 'lato-light', fontWeight: 300 }} variant="h5" component="h3">
                    {p.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div style={{ color: '#424242', display: 'flex', alignItems: 'center', padding: '2em', flexDirection: 'column', textAlign: 'center' }}>
        <h2 style={{ fontFamily: 'Orbitron', fontSize: 30, margin: 20, fontWeight: 500 }}>GET STARTED</h2>
        <Typography style={{ fontSize: '1.3rem', fontFamily: 'lato-light', fontWeight: 300 }} variant="h5" component="h3">
          Discover how easy it is to <br />
          do what you love and get paid for it
        </Typography>
        <br />
        <br />
        <Button to="/request" style={{ color: '#ffc107' }} component={Link} size="large" variant="outlined" color="primary">
          Request Invitation
        </Button>
      </div>
      <div style={{ width: '100vw', color: '#424242', display: 'flex', alignItems: 'center', padding: '1em', flexDirection: 'column', textAlign: 'center' }}>
        <p>
          <a href="/privacy">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/terms-of-service">Terms of Service</a>
        </p>
        <p>This site uses cookies. By entering the site you agree to our use of them.</p>
        <p>&copy;2021 ODDEOTRAX</p>
      </div>
    </div>
  )
}

export default Home

const useStyles = makeStyles((theme) => ({
  heroImage: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/images/studio_img_1200.webp");',
    height: 850,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
  },
  heroText: {
    position: 'relative',
    top: '30%',
    textAlign: 'center',
    maxwidth: '1200px',
  },
  mainheading: {
    fontFamily: 'orbitron',
    fontSize: '5em',
    width: '100%',
    fontWeight: 500,
    textAlign: 'center',
    color: '#ffc107',
    [theme.breakpoints.down('sm')]: { fontSize: '3em', width: '97%' },
  },
  subheading: {
    fontFamily: 'lato-light',
    fontSize: '2em',
    width: '100%',
    marginTop: '-3%',
    marginBottom: '3%',
    margin: '0px auto',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: { fontSize: '1.5em', width: '90%' },
  },
  downArrow: {
    position: 'relative',
    top: '100%',
    textAlign: 'center',
    maxwidth: '1200px',
  },
  socialImage: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/images/speaker_wall_1200.webp");',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    padding: '2em',
    flexBasis: '100%',
    color: '#fff',
    margin: 0,
  },
}))
