import { Link } from 'react-router-dom'
import { Grid, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CloudDownload, CloudUpload } from '@mui/icons-material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'

const useStyles = makeStyles((theme) => ({
  cta_button: {
    '&.MuiButton-root': {
      color: '#ffc107',
      borderColor: '#ffc107',
      [theme.breakpoints.down('sm')]: { width: '100%', marginBottom: '5px', backgroundColor: '#212121' },
    },
    '&.MuiButton-root:hover': {
      color: '#ffc107',
    },
  },
}))

export const ProjectActions = ({ project, handleDownloadClick }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardActions>
        <Grid container>
          <Grid item style={{ width: '100%', textAlign: 'right' }}>
            {project?.details.stems ? (
              <a href={project?.details.stems} style={{ textDecoration: 'none' }} download="download stems">
                <Button startIcon={<CloudDownload />} className={classes.cta_button} onClick={handleDownloadClick} size="large" variant="standard" download>
                  Download
                </Button>
              </a>
            ) : (
              ''
            )}
            <Button to={`/upload/${project?.slug}`} className={classes.cta_button} component={Link} startIcon={<CloudUpload />} size="large" variant="standard">
              Submit
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

export default ProjectActions
