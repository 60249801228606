export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'FEED_LOADED':
      return { ...state, feedlist: payload, loading: false }

    default:
      return state
  }
}

export default reducer
