import { useEffect } from 'react'
import { Rating } from '@mui/material'
import { RatingsProvider, useRatingsContext } from './context'

export const Ratings = (props) => {
  return (
    <RatingsProvider>
      <RatingsControl {...props} />
    </RatingsProvider>
  )
}

export const RatingsControl = ({ track_id, rating_attribute }) => {
  const { state, ratingsActions, ratingsDispatch } = useRatingsContext()
  const { rating } = state

  useEffect(() => {
    ratingsDispatch(ratingsActions.load(track_id, rating_attribute))
  }, [ratingsDispatch, ratingsActions, track_id, rating_attribute])

  const handleRatingsChange = (t_id, newRating) => {
    const ratingObj = { track_id: t_id, attribute: rating_attribute, value: newRating }
    ratingsDispatch(ratingsActions.create(ratingObj))
    ratingsDispatch(ratingsActions.ratingsChange(t_id))
    ratingsDispatch(ratingsActions.load(track_id, rating_attribute))
  }

  return (
    <>
      {track_id ? (
        <Rating onChange={(event, newRating) => handleRatingsChange(track_id, newRating)} style={{ pointerEvents: 'auto' }} value={rating?.value || 0} max={3} precision={1} />
      ) : (
        ''
      )}
    </>
  )
}

export default Ratings
