import { useEffect } from 'react'
import moment from 'moment'
import Markup from 'interweave'
import Nav from '../app/nav'
import { Typography, Divider, Grid, Button, Card, CardActions, CardContent, CardActionArea, CardHeader, CardMedia, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { FeedProvider, useFeedContext } from './context'
import { TopRemixersList } from '../remixes/top-remixers'
import { ProjectCountdownControl } from '../projects/project-countdown'
import Subscribe from '../subscribers'
import absoluteUrlHandler from '../utils'

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: '100%',
  },
  cardlink: {
    textDecoration: 'none',
  },
  readMoreItem: {
    display: 'none',
    [theme.breakpoints.up('md')]: { borderLeft: '1px solid #333', display: 'flex' },
    position: 'relative',
    height: '100%',
  },
  detailList: {
    listStyleType: 'none',
    padding: 0,
    marginBottom: 0,
    marginTop: '0',
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '1rem',
    lineHeight: '1.5',
  },

  sectionDesktop: { display: 'none', [theme.breakpoints.up('md')]: { display: 'flex' } },
  sectionMobile: { display: 'flex', [theme.breakpoints.up('md')]: { display: 'none' } },
  listMobile: { margin: 17, paddingTop: 0, [theme.breakpoints.up('md')]: { margin: 37 } },
}))

export const SpotlightCard = ({ title, url, details, spotlight }) => {
  const classes = useStyles()
  const splitDetails = details.content.split('.')

  return (
    <>
      <Card raised style={{ width: '100%', backgroundColor: '#000' }}>
        <a className={classes.cardlink} href={absoluteUrlHandler(url)}>
          <Grid container>
            <Grid item md={7} xs={12}>
              <CardHeader
                title={
                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid xs={8} item style={{ fontWeight: 'bold', fontFamily: 'lato-regular', color: '#999', fontSize: '.6em' }}>
                      {details.subheading.toUpperCase()}
                    </Grid>
                    <Grid xs={4} item style={{ color: '#444', fontSize: '.6em', textAlign: 'right' }}>
                      {moment(details?.pubDate).fromNow()}&nbsp;&nbsp;
                    </Grid>
                  </Grid>
                }
                subheader={
                  <span>
                    <span style={{ color: '#fff', fontWeight: 500, fontFamily: 'lato-regular', fontSize: '2em' }}>{title.toUpperCase()}</span>
                  </span>
                }
              />
              <CardContent>
                <ul className={classes.detailList}>
                  {splitDetails.map((item, i) => (
                    <li key={i}>
                      <Markup content={item} />
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button size="large" startIcon={<ReadMoreIcon />}>
                  READ MORE
                </Button>
              </CardActions>
            </Grid>
            <Grid item md={5} xs={12}>
              <CardMedia className={classes.media} image={details.image.src} alt={details.image.alt} />
            </Grid>
          </Grid>
        </a>
      </Card>
    </>
  )
}

export const FeedItem = ({ title, url, details, spotlight }) => {
  const classes = useStyles()

  return (
    <>
      <Card style={{ width: '100%' }}>
        <CardActionArea>
          <a className={classes.cardlink} href={absoluteUrlHandler(url)}>
            <Grid container>
              <Grid item md={2} xs={12}>
                <CardMedia className={classes.media} image={details.image.src} alt={details.image.alt} />
              </Grid>
              <Grid item md={9} xs={12}>
                <CardHeader
                  title={
                    <Grid container style={{ marginBottom: 8 }}>
                      <Grid xs={6} item style={{ fontWeight: 'bold', fontFamily: 'lato-regular', color: '#999', fontSize: '.6em' }}>
                        {details.subheading.toUpperCase()}
                      </Grid>
                      <Grid xs={6} item style={{ color: '#444', fontSize: '.6em', textAlign: 'right' }}>
                        {moment(details?.pubDate).fromNow()}
                      </Grid>
                    </Grid>
                  }
                  subheader={<span style={{ color: '#fff', fontFamily: 'lato-regular', fontSize: '1.2em' }}>{title.toUpperCase()}</span>}
                />
              </Grid>
              <Grid item md={1} xs={12}>
                <Box className={classes.readMoreItem}>
                  <ReadMoreIcon style={{ color: '#ffc107', position: 'relative', margin: 0, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                </Box>
              </Grid>
            </Grid>
          </a>
        </CardActionArea>
      </Card>
    </>
  )
}

export const Feed = (props) => {
  return (
    <FeedProvider>
      <List {...props} />
    </FeedProvider>
  )
}

export const List = (props) => {
  const { state, feedActions, feedDispatch } = useFeedContext()
  const { feedlist } = state
  useEffect(() => {
    feedDispatch(feedActions.list())
  }, [feedDispatch, feedActions])

  const filteredFeedList = feedlist.filter((p) => new Date(p.details.pubDate) <= moment().toDate())
  const sortedFeedlist = filteredFeedList?.sort((a, b) => new Date(b.details.pubDate) - new Date(a.details.pubDate))

  const classes = useStyles()

  return (
    <Box style={{ display: 'flex', alignContent: 'center', width: 1200, backgroundColor: '#121212' }}>
      <Box className={classes.sectionDesktop} style={{ minWidth: 200, flexDirection: 'column', backgroundColor: '#212121' }}>
        <Divider style={{ marginTop: 15, borderColor: 'transparent' }} />
        <Nav />
        <Divider style={{ marginTop: 10 }} />
        <TopRemixersList />
      </Box>
      <Box className={classes.listMobile} style={{ display: 'flex', alignContent: 'center', flexDirection: 'column', overflow: 'scroll' }}>
        <ProjectCountdownControl />
        <br />
        <Grid container spacing={1.5}>
          {sortedFeedlist?.slice(0, 1).map((f) => (
            <Grid key={f._id} container item md={12} sm={12} alignItems="center">
              {f.title ? <SpotlightCard {...f} spotlight={true} /> : <span />}
            </Grid>
          ))}
          <Grid item xs={12}>
            <br />
            <Typography style={{ textTransform: 'capitalize', fontWeight: '500', fontFamily: 'orbitron' }} variant="h6" component="h6">
              RECENT UPDATES
            </Typography>
          </Grid>
          {sortedFeedlist?.slice(1, 5).map((f) => (
            <Grid key={f._id} container item md={12} sm={12} alignItems="center">
              {f.title ? <FeedItem {...f} /> : <span />}
              <Divider />
            </Grid>
          ))}
        </Grid>
        <Subscribe form="2863829" tags={[2822281]} />
      </Box>
    </Box>
  )
}

export default Feed
