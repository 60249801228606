import axios from 'axios'

export const types = {
  ACCOUNT_LOADING: 'ACCOUNT_LOADING',
  ACCOUNT_LOADED: 'ACCOUNT_LOADED',
  ACCOUNT_LOAD_ERROR: 'ACCOUNT_LOAD_ERROR',

  ACCOUNTS_LOADING: 'ACCOUNTS_LOADING',
  ACCOUNTS_LOADED: 'ACCOUNTS_LOADED',
  ACCOUNTS_LOAD_ERROR: 'ACCOUNTS_LOAD_ERROR',
}

export const create =
  (newAccount) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.ACCOUNT_LOADING, payload: newAccount })
    const result = await axios.post(`/api/accounts`, newAccount)
    if (result.error) {
      dispatch({ type: types.ACCOUNT_LOAD_ERROR, payload: result.error })
    } else {
      dispatch({ type: types.ACCOUNT_LOADED, payload: result.data })
    }
  }

export const list =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.ACCOUNTS_LOADING, payload: true })
    const result = await axios.get(`/api/${type}`)
    if (result.error) dispatch({ type: types.ACCOUNTS_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.ACCOUNTS_LOADED, payload: result.data })
  }

export const load =
  (slug) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.ACCOUNT_LOADING, payload: true })
    const result = await axios.get(`/api/artists/${slug}`)
    if (result.error) dispatch({ type: types.ACCOUNT_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.ACCOUNT_LOADED, payload: result.data })
  }
