import { Divider } from '@mui/material'

export const TermsOfService = (props) => {
  return (
    <div style={{ flex: 'flex', width: '100vw', height: '100%', backgroundColor: '#fff' }}>
      <div style={{ color: '#424242', display: 'flex', alignItems: 'left', padding: '2em', flexDirection: 'column', textAlign: 'left' }}>
        <h1>Terms of Service</h1>
        <p>EFFECTIVE DATE: 05/01/2021</p>
        PLEASE READ THESE TERMS OF SERVICE (“Terms of Service”) CAREFULLY BEFORE USING THE ODDEOTRAX PLATFORM, AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS.
        <br />
        <h2>I.Section: The Platform</h2>
        <Divider />
        <h3>1. PREAMBLE</h3>
        ODDEOTRAX, Inc. CO, 80015, USA (“oddeotrax”, “we”, “our” or “us”), provides access to the ODDEOTRAX website (www.oddeotrax.com) and related URLs (the “Website”), and all
        services, software and data accessed via the Website (the “Services”). The Website and the Services are referred to together in these Terms of Service as the “Platform”.
        THE PLATFORM IS NOT INTENDED FOR USE BY ANYONE UNDER THE AGE OF 18. IF YOU ARE UNDER THE AGE OF 18, YOU MAY NOT USE THE PLATFORM OR PROVIDE US WITH ANY PERSONALLY
        IDENTIFIABLE INFORMATION. If you are 18 or older, you represent that you have reviewed these Terms of Service and that you understand and consent to these Terms of Service.
        These Terms of Service govern your use of the Platform. They apply solely to the Platform, and you agree to be bound by these Terms of Service. IF YOU DO NOT AGREE TO BE
        BOUND BY ALL OF THESE TERMS OF SERVICE, DO NOT USE THE PLATFORM. ODDEOTRAX is a web-based service for creative music production and offers their customers to a) provide
        audio recordings for other customers producing new versions based on the original material (so called remixes) or b) for customers to create and publish audio recordings of
        original music through “Gigs”. Remixes and original audio recordings submitted by customers can then be exploited commercially through either ODDEOTRAX or the respective
        licensor’s exploitation channels.
        <h3>2. CHANGES TO THE TERMS OF SERVICE</h3>
        We reserve the right to add, delete or amend parts of these Terms of Service at our sole discretion and at any time without prior notice to you. We will post any changes to
        the Terms of Service on this page and will update the Effective Date listed above. If you continue to access or use the Platform after such modification, you will be deemed
        to have read, understood and unconditionally agreed to such changes.
        <h3>3. DESCRIPTION OF WEBSITE AND SERVICES</h3>
        Unless otherwise stated, any features or tools which are added to the Website and/or the Services are subject to these Terms of Service. We may add, amend or remove any
        aspect of the Website and/or the Services at any time without notice. We may also impose limits on certain features and/or restrict access to parts of or the entire
        Platform without notice or liability to you or any third party. In order to use the Website, you must obtain access to the Internet and pay any service fees associated with
        such access. In addition, you must provide all equipment necessary to make such a connection to the Internet, including a computer or other access device.
        <h3>4. REGISTRATION</h3>
        To access certain Services, you may need to register for an account, and you may be asked to provide a username and a password. You agree to provide accurate, current and
        complete information required to register with the Services and/or at any other time as may be required in the course of using the Services. You further agree to maintain
        and update your registration information as required to keep it accurate, current and complete. You should keep your password confidential. You are solely responsible for
        maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that
        occur under your account or password. If you have reason to believe that your user account is no longer secure (for example, in the event of a loss, theft or unauthorized
        disclosure or use of your user ID or password), you will immediately notify ODDEOTRAX. You may be liable for the losses incurred by ODDEOTRAX or others due to any
        unauthorized use of your account. You may cancel your account with us at any time by emailing support@oddeotrax.com. We reserve the right to cancel your account at any time
        for any reason without prior notice to you. By using the Platform and providing us with your personal information upon registration or otherwise, you also accept the terms
        set forth in our Privacy Policy.
        <h3>5. INTELLECTUAL PROPERTY</h3>
        Copyrights, trademark rights, database rights, design rights and any and all other intellectual property and other rights relating to the Platform existing now or in the
        future including but not limited to computer programs, source and object code, documentation, software graphics, text, images, designs, videos, animations, databases,
        logos, domain names, trade names and trade identities (collectively referred to as the “Content”), are the property of us, our subsidiaries, affiliates and/or licensors.
        Unless expressly granted in writing by us, no rights in or to the Content except those expressly set forth within these Terms of Service are granted to you. You agree to
        abide by any and all additional notices, information or restrictions in respect of the Platform contained in any part of the Website and/or Services. Exploiting any part of
        the Platform for a purpose that is not permitted by these Terms of Service is expressly prohibited without prior written permission from us.
        <h3>6. YOUR USE OF THE PLATFORM</h3> Subject to your strict compliance with these Terms of Service, ODDEOTRAX grants you a limited, personal, non-exclusive, at any time
        revocable, non-assignable and non-transferable right and license to use the Platform. As a condition of your use of the Platform, you will not: use the Platform for any
        purpose that is unlawful or prohibited by these Terms of Service. Access to the Platform from territories where their access or use thereof is illegal is strictly
        prohibited. You are responsible for complying with all local rules, laws, and regulations including, without limitation, rules about intellectual property rights, the
        Internet, technology, data, electronic mail, or privacy; use the Platform other than to assist ODDEOTRAX with its development of the Platform and other products and
        services. You agree not to reproduce, duplicate, copy, sell, trade, resell, distribute or exploit any portion of the Platform, use of the Platform, access to the Platform,
        or Content obtained or available through the Platform, for any purpose other than to assist ODDEOTRAX with its development of the Platform and other products and services;
        interfere or attempt to interfere with or damage, impair or disable the operation of the Platform, by any means, including but not limited to providing links to third party
        sites or uploading or otherwise disseminating viruses, worms, spyware, adware or other malicious code; remove, circumvent, disable, damage or otherwise interfere with any
        security-related features of the Platform; attempt to gain unauthorized access to the Platform, or any part of it, other accounts, computer systems or networks connected to
        the Platform, or any part of it, through hacking, password mining or any other means or interfere or attempt to interfere with the proper working of the Platform or any
        activities conducted on the Platform; obtain or attempt to obtain any materials or information through any means not intentionally made available through the Platform. You
        agree neither to modify the Platform in any manner or form, nor to use modified versions of the Platform, including, without limitation, for the purpose of obtaining
        unauthorized access to the Platform; use any robot, spider, scraper, or other automated means to access the Platform for any purpose without our express written permission
        or bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Platform; utilize framing techniques to enclose any trademark, logo,
        or other Content without our express written consent. You agree not to use any meta tags or any other “hidden text” utilizing ODDEOTRAX’s name or trademarks without our
        express written consent; reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the Platform or any part thereof, except and only to
        the extent that such activity is expressly permitted by applicable law notwithstanding this limitation; modify, adapt, translate or create derivative works based upon the
        Platform or any part thereof, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation; and make any
        unsolicited offers, request, advertisements, or spam. You represent, warrant and agree that you will comply with the above acceptable use requirements. ODDEOTRAX reserves
        the right, in its sole discretion, to take such action as ODDEOTRAX sees fit in relation to any user who partakes in any of ODDEOTRAX’s prohibited uses or in breach of any
        of the other terms set forth herein. In extreme cases or as required by law or regulation, ODDEOTRAX reserves the right to take court action and/or report users to the
        relevant authorities.
        <h3>7. THIRD PARTY SITES AND SERVICES</h3>
        The Platform may contain links to other sites and services operated by third party service providers. Your use of third party services shall be solely between you and the
        third party, and subject to the applicable terms and conditions from such third party. You acknowledge and agree that we are not responsible or liable for the availability
        or accuracy of, and we do not endorse, such websites or resources or the content, products, or services on or available from such websites or resources. You acknowledge
        sole responsibility for and assume all risk arising from your use of any such third party websites or services.
        <h3>8. LINKING</h3>
        You can access other sites via links from the Website. These sites are not under our control and we are not responsible in any way for any of their contents. We make no
        representations and warranties as to any linked sites and have no liability or responsibility with respect to your use of such sites.
        <h3>9. DISCLAIMER/LIMITATION OF LIABILITY</h3>
        WITHOUT LIMITATION OF ANYTHING ELSE SET FORTH IN THESE TERMS OF SERVICE, YOU UNDERSTAND AND AGREE THAT ODDEOTRAX FURTHER LIMITS ITS LIABILITY IN CONNECTION WITH YOUR USE OF
        THE PLATFORM AS SET FORTH BELOW: UNDER NO CIRCUMSTANCES SHALL ODDEOTRAX, ITS SUBSIDIARIES, OR AFFILIATES OR THE DIRECTORS, OFFICERS, EMPLOYEES, OR ANY OTHER REPRESENTATIVES
        OF EACH OF THEM (COLLECTIVELY, THE “ODDEOTRAX ENTITIES AND INDIVIDUALS”) BE LIABLE TO YOU FOR ANY LOSS OR DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, FOR ANY
        SPECIAL, DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, ECONOMIC, PUNITIVE, OR CONSEQUENTIAL DAMAGES THAT ARE DIRECTLY OR INDIRECTLY RELATED TO (1) THE PLATFORM, OR THE CONTENTS;
        (2) THE USE OF, INABILITY TO USE, OR PERFORMANCE OF THE PLATFORM OR ANY OF THE CONTENT OR FEATURES THEREON; (3) ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY
        ODDEOTRAX OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR USE OF THE PLATFORM OR CONTENT; (4) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OWNERS AND/OR COPYRIGHT
        INFRINGEMENT; (5) ANY ERRORS OR OMISSIONS IN THE PLATFORM'S TECHNICAL OPERATION, EVEN IF FORESEEABLE OR EVEN IF THE ODDEOTRAX ENTITIES AND INDIVIDUALS HAVE BEEN ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGES WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, STRICT LIABILITY TORT (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED IN WHOLE OR IN PART BY
        NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS FAILURE, OR THEFT OR DESTRUCTION OF THE PLATFORM). NOTHING IN THESE TERMS OF SERVICE LIMITS OR EXCLUDES ODDEOTRAX’S LIABILITY
        FOR: (A) DEATH OR PERSONAL INJURY CAUSED BY ITS PROVEN NEGLIGENCE; (B) THE TORT OF DECEIT; OR (C) ANY LIABILITY WHICH CANNOT BE LIMITED OR EXCLUDED BY LAW. IF ANY OF THESE
        TERMS OF SERVICE ARE DETERMINED TO BE ILLEGAL, INVALID OR OTHERWISE UNENFORCEABLE BY REASON OF THE LAWS OF ANY STATE OR COUNTRY IN WHICH THESE TERMS OF SERVICE ARE INTENDED
        TO BE EFFECTIVE, THEN TO THE EXTENT AND WITHIN THE JURISDICTION IN WHICH THAT TERM IS ILLEGAL, INVALID OR UNENFORCEABLE, IT SHALL BE SEVERED AND DELETED FROM THESE TERMS OF
        SERVICE AND THE REMAINING TERMS SHALL SURVIVE AND CONTINUE TO BE BINDING AND ENFORCEABLE. THE ODDEOTRAX ENTITIES AND INDIVIDUALS ARE NOT RESPONSIBLE FOR ANY DAMAGE TO ANY
        USER'S COMPUTER, HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS,
        TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION.
        YOUR ACCESS TO AND USE OF THIS THE PLATFORM IS AT YOUR RISK. IF YOU ARE DISSATISFIED WITH THE PLATFORM OR ANY OF THE CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
        DISCONTINUE ACCESSING AND USING THE PLATFORM OR THE CONTENT. YOU RECOGNISE AND CONFIRM THAT IN THE EVENT YOU INCUR ANY DAMAGES, LOSSES OR INJURIES THAT ARISE OUT OF
        ODDEOTRAX’S ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION PREVENTING ANY EXPLOITATION OF ANY WEB
        SITE, PROPERTY, PRODUCT, PROGRAM, TELEVISION SHOW, MOTION PICTURE OR OTHER AUDIO/VISUAL CONTENT OWNED OR CONTROLLED BY ODDEOTRAX AND/OR ITS SUBSIDIARIES, AND/OR AFFILIATES,
        AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR EXPLOITATION OF ANY ODDEOTRAX WEB SITE, SERVICES,
        PLATFORM, PRODUCT, PROGRAM, MUSIC, TELEVISION SHOW, MOTION PICTURE OR OTHER AUDIO/VISUAL CONTENT OR ANY AND ALL ACTIVITIES OR ACTIONS RELATED THERETO.
        <h3>10. INDEMNITY</h3>
        You agree to indemnify, hold harmless, and keep ODDEOTRAX, its subsidiaries and affiliates, and their respective owners, officers, managers, members, agents and employees,
        fully and effectively indemnified with respect to any and all costs, claims, demands, investigations, liabilities, losses, damages, judgments, settlements, costs and
        expenses, including attorneys' fees arising out of or in connection with these Terms of Service, including, without limitation: (a) your use of the Platform; (b) your
        violation of these Terms of Service or any law, rule or regulation; or (c) your use of the Content. You will cooperate as fully and reasonably as required by ODDEOTRAX in
        the defense of any claim. Notwithstanding the foregoing, ODDEOTRAX retains the exclusive right to settle, compromise and pay any and all claims, demands, proceedings,
        suits, actions or causes of actions which are brought against ODDEOTRAX herein under the terms and provisions of this Section 9 and in no event shall you settle any such
        claim without ODDEOTRAX's prior written approval.
        <h3>11. GOVERNING LAW & JURISDICTION</h3>
        These Terms of Service, the Privacy Policy and their respective interpretations shall be governed by and construed in accordance with the laws of the State of Colorado. The
        exclusive place of jurisdiction – if permitted by law – is Aurora. ODDEOTRAX and you irrevocably agree, for the sole benefit of ODDEOTRAX that, subject as provided below,
        the courts of the State of Colorado shall have exclusive jurisdiction over any dispute or claim arising out of or in connection with these Terms and Conditions and Privacy
        Policy or their subject matter or formation (including non-contractual disputes or claims). Nothing in this section shall limit the right of ODDEOTRAX to take proceedings
        against you in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any
        other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
        <h3>12. GENERAL TERMS</h3>
        Unless otherwise stated, any other rules, restrictions, guidelines and terms and conditions that may be posted or made available in connection with a particular feature on
        the Website or Service are incorporated by this reference into these Terms of Service. You are solely responsible for compliance with applicable laws, rules, and
        regulations in connection with your use of the Platform and the Content, including, without limitation, those governing your transmission or use of any software or data. If
        any provision of these Terms of Service shall be held invalid or unenforceable by any court of competent jurisdiction or as a result of future legislative action, such
        holding or action shall be strictly construed and shall not affect the validity or effect of any other provision of these Terms of Service. No waiver on the part of
        ODDEOTRAX of any of these Terms of Service will be of any force or effect unless made in writing and signed by a duly authorized officer of ODDEOTRAX. Nothing in these
        Terms of Service are intended to confer on any third party (whether referred to in the Terms of Service by name, class, description or otherwise) any benefit or any right
        under any legislation in any country to enforce any provisions of these Terms of Service. These Terms of Service, together with the Privacy Policy, constitute the entire
        agreement between you and ODDEOTRAX with respect to your use of the Platform and supersede any prior agreement between you and ODDEOTRAX.
        <h3>13. TERMINATION</h3>
        You understand and agree that ODDEOTRAX will determine your compliance with these Terms of Service in its sole discretion. ODDEOTRAX reserves the right to restrict,
        suspend, deny or terminate access to all or part of the Website or any Service and to deny access to any person in its sole discretion without notice or liability of any
        kind. ODDEOTRAX maintains a policy that provides for the termination of the Website use privileges of users who are in breach of any of these Terms of Service. Any
        violation of these Terms of Service may be referred to law enforcement authorities. Upon termination of your user account or access to the Web Site, or upon demand by
        ODDEOTRAX, you must destroy all materials obtained from the Platform and all related documentation. 14. PRIVACY Registration data and certain other information about you
        are subject to our Privacy Policy. Please read the Privacy Policy for information on how your personal data and other information about you will be handled. By accepting
        these Terms of Service you are also accepting the terms of the Privacy Policy, which is incorporated herein by reference for all purposes.
        <h2>II. Section: ODDEOTRAX – Gigs: </h2>
        This II. section of the Terms of Service contains the terms for the user’s participation in “Gigs”, which result in the creation of new recordings (New Remixes and New
        Productions) in exchange for accepting and meeting the terms of participation. The general terms set out under the I. Section shall fully apply on this II. Section, if not
        set out differently. Further details about the respective gigs can, if applicable, be found on the respective website of the relevant gig. No purchase is necessary to enter
        any gig, unless explicitly stated in the terms of participation for the gig. User is a creative talent who wants to use the services of ODDEOTRAX according to the terms of
        this Agreement.
        <h3>1. SUBJECT OF AGREEMENT FOR ODDEOTRAX – GIG(S):</h3>
        ODDEOTRAX offers their Users the possibility to participate in different Gigs to create new recordings (New Remixes and New Productions): a) “REMIX GIG”: by creating audio
        remixes of original audio recordings as provided by ODDEOTRAX on its Website and to participate in Gigs (see below under § 2). The original audio recordings for creating
        the remixes are regularly made available by ODDEOTRAX’s partners and / or Users (as set out below). b) “PRODUCER GIG”: by creating new recordings by using original audio
        assets such as loops, sounds and samples as provided by ODDEOTRAX on its Website and to participate in the respective Gigs (see below under § 2). The original audio assets
        for creating the new recordings are made available by ODDEOTRAX and ODDEOTRAX’s partners.
        <h3>2. REMIX GIG PARTICIPATION</h3>
        User shall be - after creating a user account - entitled to use the original audio recordings (such as e.g. stems and additional material such as artwork, artist bio) as
        presented on ODDEOTRAX’s website for the production of new remixes based on such material (hereinafter also New Remix) to then upload them to the respective Gig Section of
        the website. By uploading the New Remix the User grants and transfers to ODDEOTRAX the exclusive and transferable right to use such New Remix for the Exploitation Period
        and unrestricted in all other respects on a worldwide basis fully and free from any third party rights.This particularly includes for the whole New Remix or parts thereof
        including the original audio recordings and any underlying musical composition (if any) and is not limited to the rights to: duplicate (e.g. as audio carrier, download),
        distribute, broadcast (e.g. radio, tv), publicly perform, store in databases, make available online (e.g. through the Internet, Mobile Networks, etc.) including streaming /
        download; edit; to lend and rent. The transfer of rights as set out before shall also include all future forms of exploitation. User agrees to also grant ODDEOTRAX the
        right to use Users name and likeness for the presentation and exploitation of the New Production. ODDEOTRAX accepts the transfer of rights. User guarantees they have the
        rights, power and authority to enter into this Agreement and to fully perform the terms hereof, and they are not hindered by any other agreement to fulfil this Agreement
        (no exclusive recording agreement, publishing agreement or agreement with any collection society) and that any New Remix is free from any third party right. Furthermore,
        User guarantees that they waive any so called moral rights or other rights with respect to the underlying musical composition in the New Remix and guarantees to not take
        any action hereto against ODDEOTRAX or any of its customers and partners (such as e.g. Labels). Herewith User agrees to indemnify, defend and hold harmless ODDEOTRAX from
        any and all claims, suits, liabilities, losses, damages and expenses (including reasonable legal fees and expenses) which arise out of or resulting from any breach of the
        terms of this Agreement by User. At ODDEOTRAX’s sole discretion the New Remix will be hosted on the website of ODDEOTRAX, whereas ODDEOTRAX is free to withdraw such New
        Remix from its service at any time. There is at least one selected participant for each Remix Gig, who will be selected by the creator of the Gig listing. It is agreed that
        selection criteria may vary for individual Gigs. Factors considered in the selection process include but are not limited to: feedback, originality, demonstration of
        technical abilities, personal taste, having satisfactorily met the requirements set forth in the terms of the gig. There is at least one selected entry for each Gig,
        whereas such New Remix may be exploited by ODDEOTRAX or the providing Label. Any exploitation of the New Remix shall be compensated as set out according to § 4.
        <h3>3. PRODUCER GIG PARTICIPATION</h3>
        Furthermore User shall be - after creating a user account - also entitled to participate in the “Producer Gigs”, which is similar to the “Remix Gig”, whereas ODDEOTRAX
        provides different audio assets for creating individual new Recordings (hereinafter also New Production) to then upload them to the respective Gig Section of the website.
        By uploading the New Production the User grants and transfers to ODDEOTRAX the transferable right to use such New Production for online exploitation only (streaming,
        download and duplication) according to the following terms: a) for the New Production to be selected, New Production User herewith transfers to ODDEOTRAX the transferable
        worldwide and with respect to online exploitation exclusive right for the Exploitation Period; b) for all other New Productions, the worldwide online exploitation rights
        are only transferred on a nonexclusive basis. ODDEOTRAX shall be transferred the right to make such New Production available on its websites for a minimum of 90 (ninety)
        days after uploading, whereas after these 90 (ninety) days ODDEOTRAX shall be entitled to make these New Productions available until User requires in writing the
        withdrawal. Notwithstanding the foregoing the transfer of rights as set out in this § 3 shall be fully and free from any third party rights. This particularly includes
        rights with respect to the whole New Production or parts thereof including the original audio assets and any underlying musical composition (if any) and is not limited: to
        the rights to: duplicate (e.g. as download), store in databases, make available online (e.g. through the Internet, Mobile Networks, etc.) including streaming / download.
        The transfer of rights as set out before shall also include all future forms of exploitation. For the avoidance of doubt ODDEOTRAX shall in addition be transferred the
        non-exclusive right to use the New Production for promotional purposes for broadcasting and making available publicly (e.g. on trade fairs, etc.). User agrees to also grant
        ODDEOTRAX the right to use Users name and likeness for the presentation and exploitation of the New Production. ODDEOTRAX accepts the transfer of rights. User guarantees
        they have the rights, power and authority to enter into this Agreement and to fully perform the terms hereof, and they are not hindered by any other agreement to fulfil
        this Agreement (no exclusive recording agreement, publishing agreement or agreement with any collection society) and that any New Production is free from any third party
        right. Furthermore, User guarantees that they waive any so called moral rights or other rights with respect to the underlying musical composition in the New Production and
        guarantees to not take any action hereto against ODDEOTRAX or any of its customers and partners (such as e.g. Labels). Herewith User agrees to indemnify, defend and hold
        harmless ODDEOTRAX from any and all claims, suits, liabilities, losses, damages and expenses (including reasonable legal fees and expenses) which arise out of or resulting
        from any breach of the terms of this Agreement by User. At ODDEOTRAX’s sole discretion the New Production will be hosted on the website of ODDEOTRAX, whereas ODDEOTRAX is
        free to withdraw such New Production from its service at any time. There is at least one selected participant for each Gig, who will be selected by the creator of the Gig
        listing. It is agreed that selection criteria may vary for individual Gigs. Factors considered in the selection process include but are not limited to: feedback,
        originality, demonstration of technical abilities, personal taste, having satisfactorily met the requirements set forth in the terms of the gig. There is at least one
        selected entry for each Gig, whereas such New Production may be exploited by ODDEOTRAX or any providing partner. Any exploitation of the New Production shall be compensated
        as set out according to § 4.
        <h3>4. REMUNERATION</h3>
        For the transfer of rights the User as a creator of a New Remix shall receive a royalty for the exploitation of the New Remix through ODDEOTRAX or any of ODDEOTRAX’s
        licensors. The User shall receive a remuneration of 15 (fifteen) % of ODDEOTRAX’s net receipts from the respective exploitation. If the User is the Supplier of the Files
        for a New Production they shall receive 70 (seventy) % of ODDEOTRAX’s net receipts from the exploitation. The same shall apply for any New Production exploited through
        ODDEOTRAX. The statement of payment for Users is due quarterly each calendar year within 90 (ninety) days after the billing date in USD. The remuneration will be paid to
        Users' bank account communicated with the effect of fulfilment. PayPal will be used for payment unless otherwise explicitly agreed in writing by ODDEOTRAX. If the
        remuneration does not exceed within an accounting period an amount of USD $50, these amounts are credited and paid with the next accounting period, as soon as the
        respective threshold is exceeded.
        <h3>5. LIABILITY</h3>
        It is agreed that ODDEOTRAX is only providing a technical platform for creating new recordings. ODDEOTRAX is not obliged to provide any rights clearing with respect to
        Files or New Remixes or New Productions.
        <h3>6. TERM</h3>
        This Agreement for the under the II. Section is concluded for the Exploitation Period, which is 2 (two) years and is extended automatically for periods of each one further
        year, if not terminated in writing.
      </div>
      <p>&nbsp;</p>
      <div style={{ width: '100vw', color: '#424242', display: 'flex', alignItems: 'center', padding: '2em', flexDirection: 'column', textAlign: 'center' }}>
        <p>
          <a href="/privacy">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/terms-of-service">Terms of Service</a>
        </p>
        <p>This site uses cookies. By entering the site you agree to our use of them.</p>
        <p>&copy;2021 ODDEOTRAX</p>
      </div>
    </div>
  )
}

export default TermsOfService
