import axios from 'axios'

export const types = {
  UPLOAD_LOADING: 'UPLOAD_LOADING',
  UPLOAD_LOADED: 'UPLOAD_LOADED',
  UPLOAD_LOAD_ERROR: 'UPLOAD_LOAD_ERROR',
  UPLOAD_LOAD_PROGRESS: 'UPLOAD_LOAD_PROGRESS',
}

export const upload =
  (selectedFile, projectPath, remixMeta) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.UPLOAD_LOADING, payload: selectedFile })
    const fd = new FormData()
    fd.append('upload', selectedFile)
    fd.append('meta', JSON.stringify(remixMeta))

    var result = ''
    if (selectedFile.type !== 'audio/mpeg') {
      result = { error: { message: 'Wrong File Type' } }
    } else {
      result = await axios.post(`/api/upload/${projectPath}`, fd, {
        onUploadProgress: (progressEvent) => {
          var progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          dispatch({ type: types.UPLOAD_LOAD_PROGRESS, payload: progress })
        },
      })
    }
    if (result.error) {
      dispatch({ type: types.UPLOAD_LOAD_ERROR, payload: result.error })
    } else {
      dispatch({ type: types.UPLOAD_LOADED, payload: result.data })
    }
  }

export const projectSubmitEvent =
  (slug) =>
  async ({ dispatch, history }) => {
    const eventData = {
      action: 'submit',
      data: { slug: slug },
      domain: 'projects',
    }
    await axios.post(`/api/evt`, eventData)
  }
