import Markup from 'interweave'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CloudUpload } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { Card, Avatar, Typography, Button } from '@mui/material'
import Ratings from '../ratings'
import { RemixProvider, useRemixContext } from './context'
import MiniAudioPlayer from '../mini-audio-player'

const useStyles = makeStyles((theme) => ({
  remixlist: {
    padding: 15,
    width: '100%',
    marginBottom: 10,
  },
  remixtitle: {
    fontSize: '1.1rem',
    fontWeight: '500',
    fontFamily: 'lato-regular',
  },
  remixsubtitle: {
    fontSize: '0.9rem',
    fontWeight: '300',
    fontFamily: 'lato-light',
    color: 'rgba(255, 255, 255, 0.7)',
  },
}))

export const RemixList = (props) => {
  return (
    <RemixProvider>
      <Remixes {...props} />
    </RemixProvider>
  )
}

export const Remixes = ({ setSongs, currentSong, playing, playPause, project_slug }) => {
  const classes = useStyles()
  const { state, remixActions, remixDispatch } = useRemixContext()
  const { remixes } = state

  useEffect(() => {
    remixDispatch(remixActions.listByProjectSlug(project_slug))
  }, [remixDispatch, remixActions, project_slug])

  useEffect(() => {
    setSongs(remixes)
  })

  return (
    <Grid container>
      {remixes && remixes?.length > 0 ? (
        remixes?.map((remix, i) => (
          <Card key={i} className={classes.remixlist}>
            <Grid container item xs={12} style={{ padding: 3 }}>
              <Grid item sm={1} xs={2} sx={{ paddingTop: 0.5, marginBottom: 1, paddingLeft: '1%' }}>
                <Avatar alt={`${remix?.remixer?.givenName} ${remix?.remixer?.familyName}`} src={remix?.remixer?.avatar} sx={{ width: 60, height: 60 }} />
              </Grid>
              <Grid item sm={1} xs={2} sx={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '40%', left: '60%', transform: 'translate(-50%, -50%)' }}>
                  <MiniAudioPlayer size={30} color={'#ffc107'} playPause={playPause} currentSong={currentSong} song={i + 1} playing={playing} />
                </div>
              </Grid>
              <Grid item sm={4} xs={8} sx={{ paddingTop: 0.5, marginBottom: 1, paddingLeft: '1%' }}>
                <span className={classes.remixtitle}>
                  Remixer: <Markup content={remix?.remixer.alias ? remix?.remixer?.alias : `${remix?.remixer?.givenName} ${remix?.remixer?.familyName}`} />
                </span>
                <br />
                <span className={classes.remixsubtitle}>
                  Genre: <Markup content={remix?.genre} />
                </span>
              </Grid>
              <Grid container item sm={6} xs={12} style={{ textAlign: 'center', marginTop: 2 }}>
                {remix._id ? (
                  <Grid container item xs={12} style={{ textAlign: 'center' }}>
                    <Grid item xs={3}>
                      <Typography component="legend">Quality</Typography>
                      <Ratings track_id={remix._id} rating_attribute={'quality'} />
                    </Grid>
                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={3}>
                      <Typography component="legend">Atmosphere</Typography>
                      <Ratings track_id={remix._id} rating_attribute={'atmosphere'} />
                    </Grid>
                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={3}>
                      <Typography component="legend">Creativity</Typography>
                      <Ratings track_id={remix._id} rating_attribute={'creativity'} />
                    </Grid>
                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Card>
        ))
      ) : (
        <Card className={classes.remixlist}>
          <Grid container justifyContent="center" item xs={12} style={{ padding: 3 }}>
            <Button to={`/upload/${project_slug}`} component={Link} startIcon={<CloudUpload />} style={{ color: '#ffc107' }} size="large" variant="standard">
              Submit your remix
            </Button>
          </Grid>
        </Card>
      )}
    </Grid>
  )
}

export default RemixList

/*



*/
