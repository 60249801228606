import axios from 'axios'

export const types = {
  SUBSCRIBER_LOADING: 'SUBSCRIBER_LOADING',
  SUBSCRIBER_LOADED: 'SUBSCRIBER_LOADED',
  SUBSCRIBER_LOAD_ERROR: 'SUBSCRIBER_LOAD_ERROR',
}

export const subscribe =
  (subscriber) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.SUBSCRIBER_LOADING, payload: true })
    const subscriberData = {
      email: subscriber.email,
      form: subscriber.form,
      tags: subscriber.tags,
    }
    const result = await axios.post(`/api/subscribe`, subscriberData)
    if (result.error) dispatch({ type: types.SUBSCRIBER_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.SUBSCRIBER_LOADED, payload: result.data })
  }
