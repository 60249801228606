export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'ACCOUNTS_LOADED':
      return { ...state, accounts: payload, loading: false }
    case 'ACCOUNT_LOADED':
      return { ...state, account: payload, loading: false }

    default:
      return state
  }
}

export default reducer
