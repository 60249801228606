import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: true,
  feed: {
    _id: '',
    title: '',
    url: '',
    details: {
      image: {
        src: '',
        alt: '',
      },
      subheading: '',
      content: '',
    },
  },
  feedlist: [],
}

const FeedContext = createContext()

export const FeedProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const feedDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.feed = state
  return <FeedContext.Provider value={{ state, feedActions: actions, feedDispatch }}>{children}</FeedContext.Provider>
}

export const useFeedContext = () => {
  const context = useContext(FeedContext)
  if (context === undefined) throw new Error('useFeedContext must be used within a FeedProvider')
  return context
}
