export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'UPLOAD_LOADED':
      return { ...state, uploadedFile: payload, loading: false }
    case 'UPLOAD_LOADING':
      return { ...state, selectedFile: payload, loading: true, error: '' }
    case 'UPLOAD_LOAD_PROGRESS':
      return { ...state, progress: payload, loading: true }
    case 'UPLOAD_LOAD_ERROR':
      return { ...state, error: payload, loading: false }
    default:
      return state
  }
}

export default reducer
