export const absoluteUrlHandler = (absoluteUrl) => {
  let properUrl = absoluteUrl
  if (process.env.REACT_APP_ENV_DEV) {
    const url = new URL(absoluteUrl)
    properUrl = `http://localhost:3000${url.pathname}`
  }

  return properUrl
}

export default absoluteUrlHandler
