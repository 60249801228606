import React, { useEffect } from 'react'
import Markup from 'interweave'
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { GenresProvider, useGenresContext } from './context'

export const GenreSelectControl = (props) => {
  return (
    <GenresProvider>
      <SelectBox {...props} />
    </GenresProvider>
  )
}

export const SelectBox = ({ params, ...props }) => {
  const { state, genresActions, genresDispatch } = useGenresContext()
  const { genres } = state
  const { required } = props
  const { fullWidth } = props
  const [genre, setGenre] = React.useState('')

  useEffect(() => {
    genresDispatch(genresActions.list())
  }, [genresDispatch, genresActions])

  const handleChange = (event) => {
    setGenre(event.target.value)
  }

  return (
    <Box xs={{ minWidth: 120 }}>
      <FormControl required={required} fullWidth={fullWidth}>
        <InputLabel id="select-genre-label">Select a Genre</InputLabel>
        <Select labelId="select-genre-label" id="select-genre" variant="standard" value={genre} label="Genre" onChange={handleChange}>
          {genres?.map((p) => (
            <MenuItem key={p._id} value={p.genre}>
              <Markup content={p.genre} />
            </MenuItem>
          ))}
        </Select>
        <input id="select-genre-value" value={genre} style={{ display: 'none' }} readOnly />
      </FormControl>
    </Box>
  )
}

export default GenreSelectControl
