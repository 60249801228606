import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: true,
  notification: {
    _id: '',
    title: '',
    url: '',
    type: 0,
    viewed: false,
    details: {
      subheading: '',
      content: '',
      created_by: '',
      pubDate: '',
    },
  },
  notifications: [],
}

const NotificationsContext = createContext()

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const notificationsDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.releases = state
  return <NotificationsContext.Provider value={{ state, notificationsActions: actions, notificationsDispatch }}>{children}</NotificationsContext.Provider>
}

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext)
  if (context === undefined) throw new Error('useNotificationsContext must be used within a NotificationsProvider')
  return context
}
