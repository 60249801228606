import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const NotFound = (props) => {
  return (
    <div style={{ flex: 'flex', width: '100vw', justifyContent: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '2em', flexDirection: 'column', textAlign: 'center' }}>
        <Typography style={{ margin: 20 }} variant="h1" component="h2">
          <span style={{ fontFamily: 'Orbitron', fontSize: 72, color: '#ffc107' }}>00PS!</span>
        </Typography>
        <Typography style={{ margin: 10 }} variant="h5" component="h3">
          That {props.type ? props.type : 'page'} wasn't found
        </Typography>
        {props?.text} {props.text && <div style={{ margin: 20 }}>- OR -</div>}
        <Button to="/" style={{ color: '#ffc107', minWidth: '25%' }} component={Link} size="large" variant="outlined" color="primary">
          Head Home
        </Button>
      </div>
    </div>
  )
}

export default NotFound
