import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Typography, Button, Box, Checkbox, CircularProgress, FormControl } from '@mui/material'
import { UploadProvider, useUploadContext } from './context'
import { UploadFile, Check } from '@mui/icons-material'
import ProjectSelectControl from '../projects/project-select'
import GenreSelectControl from './genre-select'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    textAlign: 'center',
    paddingTop: 100,
  },
  headline: {
    paddingBottom: 50,
  },
  form_control_btn: {
    width: '100%',
    textAlign: 'center',
  },
  cta_button: {
    '&.MuiButton-root': {
      color: '#ffc107',
      borderColor: '#ffc107',
      marginBottom: 20,
      width: '100%',
      [theme.breakpoints.down('sm')]: { width: '100%', marginBottom: '5px' },
    },
    '&.MuiButton-root:hover': {
      color: '#ffc107',
    },
  },
}))

export const Upload = (props) => {
  return (
    <UploadProvider>
      <UploadPage {...props} />
    </UploadProvider>
  )
}

function CircularProgressWithLabel(props) {
  return (
    <Box style={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="primary">
          {props.value ? Math.round(props.value) < 100 ? `${Math.round(props.value)}%` : <Check style={{ color: '#ffc107', paddingTop: '6px' }} /> : ''}
        </Typography>
      </Box>
    </Box>
  )
}

export const UploadPage = ({ computedMatch, params, ...rest }) => {
  const fileInput = useRef(null)
  const [checked, setChecked] = useState(false)
  const [uploadErrorMsg, setUploadErrorMsg] = useState('')
  const { state, uploadActions, uploadDispatch } = useUploadContext()
  const projectSlug = computedMatch?.params?.slug

  const classes = useStyles()
  const { progress, selectedFile, error } = state

  const validateSelectHandler = () => {
    const projectSelected = document.getElementById('select-project-value')
    const selectLabel = document.getElementById('select-project-label')
    const genreSelected = document.getElementById('select-genre-value')
    const genreLabel = document.getElementById('select-genre-label')

    let isValid = true

    if (!projectSelected.value) {
      selectLabel.style.color = 'red'
      setUploadErrorMsg('Project is required')
      isValid = false
    } else {
      selectLabel.style.color = 'inherit'
    }

    if (!genreSelected.value) {
      genreLabel.style.color = 'red'
      setUploadErrorMsg('Genre is required')
      isValid = false
    } else {
      genreLabel.style.color = 'inherit'
    }

    return isValid
  }

  const handleTermAgreement = (event) => {
    setChecked(event.target.checked)
  }

  const chooseFileClickHandler = async () => {
    if (validateSelectHandler()) {
      setUploadErrorMsg('')
      fileInput.current.click()
    }
  }

  //TODO: Get genre and bpm values and submit as remixMeta on upload
  const fileUploadHandler = (event) => {
    const projectPath = document.getElementById('select-project-value').value
    const selectedGenre = document.getElementById('select-genre-value').value
    const remixMeta = { genre: selectedGenre }
    uploadDispatch(uploadActions.upload(event.target.files[0], projectPath, remixMeta))
    uploadDispatch(uploadActions.projectSubmitEvent(projectPath))
  }

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.headline} component="h1">
          Submit an MP3 File
        </Typography>
        <form className={classes.form_container}>
          <ProjectSelectControl required fullWidth slug={projectSlug} onChange={validateSelectHandler} />
          <br />
          <GenreSelectControl required fullWidth onChange={validateSelectHandler} />
          <br />
          <Typography variant="p" component="p">
            I have read and agree to the terms of the <br />
            <Link to="/standard-remix-agreement">standard remix agreement</Link>
            <br />
            if my remix is selected. <Checkbox checked={checked} onChange={handleTermAgreement} />
          </Typography>
          <br />
          <FormControl className={classes.form_control_btn}>
            <input ref={fileInput} style={{ display: 'none' }} type="file" onChange={fileUploadHandler} />
            <Button id="upload-btn" disabled={!checked} className={classes.cta_button} onClick={chooseFileClickHandler} startIcon={<UploadFile />} size="large" variant="standard">
              Upload MP3 to Submit
            </Button>
          </FormControl>
        </form>
        {selectedFile ? selectedFile.name : ''}
        <br />
        {error.message || uploadErrorMsg ? (
          <span style={{ color: 'red' }}>
            {error.message}
            <br />
            {uploadErrorMsg}
          </span>
        ) : (
          <CircularProgressWithLabel style={{ color: '#ffc107' }} value={progress} />
        )}
      </div>
    </>
  )
}

export default Upload
