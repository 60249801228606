import axios from 'axios'
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, sendEmailVerification, FacebookAuthProvider } from 'firebase/auth'
import { auth } from '../firebase'
import { types } from './constants'
import qs from 'qs'

export const createUser =
  (creds) =>
  async ({ dispatch, history }) => {
    try {
      dispatch({ type: types.USER_CREATING, payload: true })
      const result = await createUserWithEmailAndPassword(auth, creds.email, creds.password)
      dispatch({ type: types.USER_CREATED, payload: result })
      if (result?.user?.email) {
        sendEmailVerification(auth.currentUser)
        dispatch({ type: types.USER_VERIFICATION, payload: {} })
      }
      history.push('/')
    } catch (error) {
      dispatch({ type: types.USER_CREATE_ERROR, payload: { error: error?.message } })
    }
  }

export const authenticate =
  (provider, creds, location) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.USER_AUTHENTICATING, payload: true })
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    try {
      const result = await getProvider(provider, auth, creds)
      if (result?.user?.accessToken) {
        const user = await axios.get('/api/users/current', { headers: { authorization: `Bearer ${result?.user?.accessToken}` } })
        if (user.error) dispatch({ type: types.USER_FETCH_ERROR, payload: { error: user.error } })
        dispatch({ type: types.USER_FETCHED, payload: user.data })
        dispatch({ type: types.USER_AUTHENTICATED, payload: { authenticated: true } })
        history.push(query.redirect ? `.${query.redirect}` : '/')
      }
    } catch (error) {
      dispatch({ type: types.USER_AUTHENTICATION_ERROR, payload: { error: error?.message } })
    }
  }

export const unauthenticated =
  () =>
  async ({ dispatch, history }) =>
    dispatch({ type: types.USER_UNAUTHENTICATED })

export const getProvider = (provider, auth, creds) => {
  switch (provider) {
    case 'google':
      return signInWithPopup(auth, new GoogleAuthProvider())
    case 'facebook':
      return signInWithPopup(auth, new FacebookAuthProvider())
    case 'emailAndPass':
      return signInWithEmailAndPassword(auth, creds.email, creds.password)
    default:
      throw new Error(`Provider: ${provider} not available`)
  }
}

export const currentUser =
  () =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.USER_FETCHING, payload: true })
    const result = await axios.get('/api/users/current')
    if (result.error) dispatch({ type: types.USER_FETCH_ERROR, payload: { error: result.error } })
    dispatch({ type: types.USER_FETCHED, payload: result.data })
  }

export const logout =
  () =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.USER_LOGOUT })
    auth.signOut()
    history.push('/')
  }

export const clearError =
  () =>
  async ({ dispatch, history }) =>
    dispatch({ type: types.ERROR_CLEAR })

export const list =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.USERS_LOADING, payload: true })
    const result = await axios.get(`/api/users`)
    if (result.error) dispatch({ type: types.USERS_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.USERS_LOADED, payload: result.data })
  }

  export const accept =
  (user_id) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.USER_UPDATING, payload: true })
    const result = await axios.post(`/api/users/accept/${user_id}`)
    if (result.error) dispatch({ type: types.USER_UPDATE_ERROR, payload: { error: result.error } })
    dispatch({ type: types.USER_ACCEPTED, payload: result.data })
  }

  export const save =
  (user) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.USER_SAVING, payload: true })
    const result = await axios.post(`/api/users/${user.id}`, user)
    if (result.error) dispatch({ type: types.USER_SAVE_ERROR, payload: { error: result.error } })
    dispatch({ type: types.USER_SAVED, payload: result.data })
  }


