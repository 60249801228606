import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import * as Joi from 'joi'
import { AccountProvider, useAccountContext } from './context'
import { Card, Typography, TextField, Button, Link, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: 'red',
  },
  form: {
    padding: 20,
  },
}))

export const AccountCreate = (props) => {
  return (
    <AccountProvider>
      <CreateForm {...props} />
    </AccountProvider>
  )
}

const schema = Joi.object({
  name: Joi.string().required(),
  image: Joi.string().required(),
  type: Joi.string().required(),
  tag1: Joi.string().required(),
  tag2: Joi.string().required(),
  desc: Joi.string().required(),
  producer: Joi.string().required(),
})

export const CreateForm = ({ computedMatch, params, ...rest }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: { type: 'artist' },
  })

  const { accountActions, accountDispatch } = useAccountContext()

  const submitForm = (data) => {
    const tags = [data.tag1, data.tag2]
    accountDispatch(accountActions.create({ ...data, tags }))
    reset()
  }

  const classes = useStyles()

  return (
    <div className={classes.root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100vw' }}>
      <Card
        variant="outlined"
        style={{
          backgroundColor: '#212121',
          display: 'flex',
          flexDirection: 'column',
          width: 1100,
          padding: '1em',
          color: 'white',
        }}
      >
        &nbsp;
        <Typography variant="h5">CREATE A NEW ARTIST</Typography>
        <form className={classes.form} onSubmit={handleSubmit(submitForm)}>
          <TextField
            name="type"
            label="Account Type"
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            variant="standard"
            {...register('type')}
          />
          <br />
          <br />
          <TextField name="name" label="Artist Name" fullWidth variant="standard" {...register('name')} />
          {errors.name && <p className={classes.error}>"Artist Name" is required</p>}
          <br />
          <br />
          <TextField name="image" label="Artist Image" fullWidth variant="standard" {...register('image')} />
          {errors.image && <p className={classes.error}>"Artist Image" is required</p>}
          <br />
          <br />
          <TextField name="tag1" label="Primary Music Genre" fullWidth variant="standard" {...register('tag1')} />
          {errors.tag1 && <p className={classes.error}>"Primary Music Genre" is required</p>}
          <br />
          <br />
          <TextField name="tag2" label="Secondary Music Genre" fullWidth variant="standard" {...register('tag2')} />
          {errors.tag2 && <p className={classes.error}>"Secondary Music Genre" is required</p>}
          <br />
          <br />
          <TextField name="producer" label="Artist Real Name" fullWidth variant="standard" {...register('producer')} />
          {errors.producer && <p className={classes.error}>"Artist Real Name" is required</p>}
          <br />
          <br />
          <br />
          <TextField name="desc" label="Artist Description" rows={4} fullWidth placeholder="A few sentences" multiline {...register('desc')} />
          {errors.desc && <p className={classes.error}>"Artist Description" is required</p>}
          <br />
          <br />
          <br />
          <Button fullWidth type="submit" variant="outlined">
            SUBMIT
          </Button>
          <br />
          <br />
          <Box style={{ width: '100%', textAlign: 'center', fontSize: '14px' }}>
            <Link onClick={() => reset()}>RESET FORM</Link>
          </Box>
          <br />
          <br />
        </form>
      </Card>
    </div>
  )
}

export default AccountCreate
