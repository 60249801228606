import axios from 'axios'

export const types = {
  RELEASE_LOADING: 'RELEASE_LOADING',
  RELEASE_LOADED: 'RELEASE_LOADED',
  RELEASE_LOAD_ERROR: 'RELEASE_LOAD_ERROR',

  RELEASES_LOADING: 'RELEASES_LOADING',
  RELEASES_LOADED: 'RELEASES_LOADED',
  RELEASES_LOAD_ERROR: 'RELEASES_LOAD_ERROR',
}

export const list =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.RELEASES_LOADING, payload: true })
    const result = await axios.get(`/api/releases`)
    if (result.error) dispatch({ type: types.RELEASES_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.RELEASES_LOADED, payload: result.data })
  }

export const load =
  (slug) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.RELEASE_LOADING, payload: true })
    const result = await axios.get(`/api/releases/slug/${slug}`)
    if (result.error) dispatch({ type: types.RELEASE_LOAD_ERROR, payload: { error: result.error } })

    dispatch({ type: types.RELEASE_LOADED, payload: result.data })
  }
