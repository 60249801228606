import { Chip, CardHeader, Card, CardActionArea, CardMedia, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: { width: '100%' },
    textDecoration: 'none',
    '&.MuiPaper-root': {
      color: 'white',
    },
  },
  media: {
    minHeight: 300,
    [theme.breakpoints.down('sm')]: { height: 300 },
    padding: 0,
  },
}))

export const Artist = ({ account }) => {
  const classes = useStyles()
  const artistLinkTo = `/releases/${account?.slug}`
  return (
    <Card className={classes.root} component={Link} to={artistLinkTo}>
      <CardActionArea>
        <CardHeader
          title={<span style={{ fontWeight: '500', fontFamily: 'lato-regular', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{account?.name || ''}</span>}
          subheader={
            <span
              style={{
                textTransform: 'uppercase',
                marginBottom: 0,
                marginTop: 10,
                color: 'rgb(153, 153, 153)',
                fontWeight: 'bold',
                fontFamily: 'lato-regular',
                fontSize: '0.9em',
                overflow: 'hidden',
              }}
            >
              {account?.producer || ''}
            </span>
          }
        />
        <CardMedia className={classes.media} image={account?.image} title={account?.name || ''} />
        <CardContent style={{ textAlign: 'right' }}>
          <Chip style={{ marginRight: 5 }} key={account?.tags[0]} color="primary" size="large" variant="outlined" label={account?.tags[0]} />
          <Chip style={{ marginRight: 5 }} key={account?.tags[1]} color="primary" size="large" variant="outlined" label={account?.tags[1]} />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default Artist
