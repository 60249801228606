export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'REMIXES_LOADED':
      return { ...state, remixes: payload, loading: false }
    case 'REMIX_LOADED':
      return { ...state, remix: payload, loading: false }

    default:
      return state
  }
}

export default reducer
