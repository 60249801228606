import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: false,
  genres: [],
  error: {},
}

const GenresContext = createContext()

export const GenresProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const genresDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.upload = state
  return <GenresContext.Provider value={{ state, genresActions: actions, genresDispatch }}>{children}</GenresContext.Provider>
}

export const useGenresContext = () => {
  const context = useContext(GenresContext)
  if (context === undefined) throw new Error('useGenresContext must be used within a GenresProvider')
  return context
}
