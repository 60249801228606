import { Card, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export const Admin = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100vw' }}>
      <Card
        variant="outlined"
        style={{
          backgroundColor: '#212121',
          display: 'flex',
          flexDirection: 'column',
          width: 1100,
          padding: '1em',
          color: 'white',
        }}
      >
        &nbsp;
        <Typography variant="h5">ADMIN SWITCHBOARD</Typography>
        <Typography variant="p">
          Place links to admin controlled pages here:
          <ul>
            <li>
              <Link to="../artists/create">Create an Artist</Link>
            </li>
            <li>
              <Link to="../projects/create">Create a Project</Link>
            </li>
            <li>
              <Link to="#">Create a Release</Link>
            </li>
            <li>
              <Link to="../users/invitations">Invitation Requests</Link>
            </li>
          </ul>
        </Typography>
      </Card>
    </div>
  )
}

export default Admin
