import React, { useEffect, useState } from 'react'
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { ProjectProvider, useProjectContext } from '../context'
import moment from 'moment'

export const ProjectSelectControl = (props) => {
  return (
    <ProjectProvider>
      <SelectBox {...props} />
    </ProjectProvider>
  )
}

export const SelectBox = ({ params, ...props }) => {
  const { state, projectActions, projectDispatch } = useProjectContext()
  const { projects } = state
  const { slug } = props
  const { required } = props
  const { fullWidth } = props
  const [defaultValue, setDefaultValue] = useState(slug || '')
  const activeProjects = projects.filter((project) => moment(project.open_date).toDate() <= moment().toDate() && moment(project.close_date).toDate() > moment().toDate())

  useEffect(() => {
    projectDispatch(projectActions.list())
  }, [projectDispatch, projectActions])

  const handleChange = (event) => {
    setDefaultValue(event.target.value)
  }

  return (
    <Box xs={{ minWidth: 120 }}>
      <FormControl required={required} fullWidth={fullWidth}>
        <InputLabel id="select-project-label">Select a Project</InputLabel>
        <Select labelId="select-project-label" id="select-project" value={defaultValue} variant="standard" label="Project" onChange={handleChange}>
          {activeProjects?.map((p) => (
            <MenuItem key={p._id} value={p.slug}>
              {p.title}
            </MenuItem>
          ))}
        </Select>
        <input id="select-project-value" value={defaultValue} style={{ display: 'none' }} readOnly />
      </FormControl>
    </Box>
  )
}

export default ProjectSelectControl
