import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  account: { name: '', producer: '', image: '', tags: [], media: {}, shops: [], copyright: {}, loading: true },
  accounts: [],
}

const AccountContext = createContext()

export const AccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const accountDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.artist = state
  return <AccountContext.Provider value={{ state, accountActions: actions, accountDispatch }}>{children}</AccountContext.Provider>
}

export const useAccountContext = () => {
  const context = useContext(AccountContext)
  if (context === undefined) throw new Error('useAccountContext must be used within an AccountProvider')
  return context
}
