import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Typography } from '@mui/material'
import { ProjectProvider, useProjectContext } from '../context'
import moment from 'moment'

export const ProjectCountdownControl = (props) => {
  return (
    <ProjectProvider>
      <CountdownControl {...props} />
    </ProjectProvider>
  )
}

export const CountdownControl = () => {
  const { state, projectActions, projectDispatch } = useProjectContext()
  const { projects } = state
  const activeProjects = projects.filter((project) => moment(project.open_date).toDate() <= moment().toDate() && moment(project.close_date).toDate() > moment().toDate())
  const sortedProjects = activeProjects?.sort((a, b) => new Date(a.close_date) - new Date(b.close_date))
  const closingProjects = sortedProjects.filter((project) => moment.duration(moment(project.close_date).diff(moment().toDate())).as('days') < 11)

  useEffect(() => {
    projectDispatch(projectActions.list())
  }, [projectDispatch, projectActions])

  return (
    <>
      {closingProjects.slice(0, 1).map((p) => (
        <Link to={`./projects/${p.slug}` || ''} style={{ textDecoration: 'none' }}>
          <Alert variant="outlined" severity="info" sx={{ width: '100%' }}>
            <Typography key={p._id} variant="body2" color="text.secondary">
              The "{p.title}" gig closes {moment(p?.close_date).fromNow()}
            </Typography>
          </Alert>
        </Link>
      ))}
    </>
  )
}

export default ProjectCountdownControl
