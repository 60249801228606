import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Box, CardContent, Chip, Card, CardActionArea, CardHeader, CardMedia, Divider, Grid, Typography, Alert, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Markup from 'interweave'
import { ReleaseProvider, useReleaseContext } from './context'
import qs from 'qs'
import Nav from '../app/nav'
import { TopRemixersList } from '../remixes/top-remixers'

const useStyles = makeStyles((theme) => ({
  root: { textDecoration: 'none' },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardlink: {
    textDecoration: 'none',
    color: '#fff',
  },
  sectionDesktop: { display: 'none', [theme.breakpoints.up('md')]: { display: 'flex' } },
  sectionMobile: { display: 'flex', [theme.breakpoints.up('md')]: { display: 'none' } },
  listMobile: { margin: 0, paddingTop: 10, [theme.breakpoints.up('md')]: { margin: 20 } },
}))

export const ErrorBanner = ({ message }) => {
  return (
    <Grid container alignItems="center" style={{ marginBottom: '20px', width: '100%' }}>
      <Grid item xs={12} style={{ width: '100%', verticalAlign: 'middle', lineHeight: '50px', height: 50, textAlign: 'center' }}>
        <Alert severity="error">{message}</Alert>
      </Grid>
    </Grid>
  )
}

export const InfoBanner = ({ message }) => {
  return (
    <Grid container alignItems="center" style={{ marginBottom: '20px', width: '100%' }}>
      <Grid item xs={12} style={{ width: '100%', verticalAlign: 'middle', lineHeight: '50px', height: 50, textAlign: 'center' }}>
        <Alert severity="info">{message}</Alert>
      </Grid>
    </Grid>
  )
}

export const ReleaseItem = ({ slug, title, artist, image, media, tags, type, copyright }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const urlPath = pathname.includes(artist.slug) ? `${pathname}/${slug}` : `${pathname}/${artist.slug}/${slug}`
  return (
    <Card style={{ width: '100%', margin: '0px 1px 20px 1px' }}>
      <CardActionArea>
        <a className={classes.cardlink} href={urlPath}>
          <Grid container>
            <Grid item md={5} xs={12}>
              <CardContent>
                <CardMedia className={classes.media} image={image} alt={title} />
              </CardContent>
            </Grid>
            <Grid item md={7} xs={12}>
              <CardHeader
                avatar={<Avatar alt={artist.name} src={artist.image} sx={{ width: 56, height: 56 }} />}
                title={<span style={{ fontWeight: '500', color: '#fff', fontFamily: 'lato-regular', fontSize: '1.5rem' }}>{title}</span>}
                subheader={
                  <span
                    style={{
                      textTransform: 'uppercase',
                      marginBottom: 0,
                      marginTop: 10,
                      color: 'rgb(153, 153, 153)',
                      fontWeight: 'bold',
                      fontFamily: 'lato-regular',
                      fontSize: '1.1em',
                      overflow: 'hidden',
                    }}
                  >
                    {artist.name}
                  </span>
                }
              />
              <CardContent>
                <Divider style={{ marginTop: '-22px' }} />
                <br />
                <Chip style={{ marginRight: 5 }} key={tags[0]} color="primary" size="large" variant="outlined" label={tags[0]} />
                <Chip style={{ marginRight: 5 }} key={tags[1]} color="primary" size="large" variant="outlined" label={tags[1]} />
                <p style={{ fontSize: '1rem', lineHeight: '1.5', color: '#fff' }}>
                  <Markup content={media.body} />
                </p>
                <p style={{ textTransform: 'uppercase', fontSize: '.8rem', lineHeight: '1.5', color: '#fff', fontWeight: '300', fontFamily: 'lato-light' }}>
                  Producer: <Markup content={artist.producer} />
                  <br />
                  Release Type: <Markup content={type} />
                  <br />
                  <Markup content={copyright.holder} /> | <Markup content={copyright.year} />
                </p>
              </CardContent>
            </Grid>
          </Grid>
        </a>
      </CardActionArea>
    </Card>
  )
}

export const List = (props) => {
  return (
    <ReleaseProvider>
      <Releases {...props} />
    </ReleaseProvider>
  )
}

export const Releases = ({ computedMatch, ...props }) => {
  const { state, releaseActions, releaseDispatch } = useReleaseContext()
  const { search } = useLocation()
  const qsparam = qs.parse(search, { ignoreQueryPrefix: true })
  const [showError] = useState(qsparam.e || '')
  const artistSlug = computedMatch?.params?.artist
  const classes = useStyles()
  const { releases } = state
  const filteredReleases = artistSlug ? releases.filter((r) => r.artist.slug === artistSlug) : releases

  useEffect(() => {
    releaseDispatch(releaseActions.list())
  }, [releaseDispatch, releaseActions])

  return (
    <Box style={{ display: 'flex', alignContent: 'center', width: 1200, backgroundColor: '#121212' }}>
      <Box className={classes.sectionDesktop} style={{ minWidth: 200, flexDirection: 'column', backgroundColor: '#212121' }}>
        <Divider style={{ marginTop: 15, borderColor: 'transparent' }} />
        <Nav />
        <Divider style={{ marginTop: 10 }} />
        <TopRemixersList />
      </Box>
      <Box className={classes.listMobile} style={{ display: 'flex', alignContent: 'center', flexDirection: 'column', overflow: 'scroll' }}>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: '1em' }}>
          {showError === 'rnf' && <ErrorBanner message={'Release Not Found: Try Again'} />}
          <Typography style={{ fontWeight: '500', fontFamily: 'orbitron' }} variant="h4" component="h1">
            Releases
          </Typography>
          <br />
          {filteredReleases.length > 0 ? (
            <Grid container spacing={2}>
              {filteredReleases.map((p) => (
                <Grid key={p._id} container item xs={12} alignItems="center">
                  {p.artist ? <ReleaseItem {...p} /> : <span />}
                </Grid>
              ))}
            </Grid>
          ) : (
            <InfoBanner
              message={
                <span>
                  No releases found for this artist. Would you like to <Link to="/releases">see all releases</Link> instead?
                </span>
              }
            />
          )}
        </div>
      </Box>
    </Box>
  )
}

export default List
