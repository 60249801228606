import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: true,
  rating: {},
}

const RatingsContext = createContext()

export const RatingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const ratingsDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.ratings = state
  return <RatingsContext.Provider value={{ state, ratingsActions: actions, ratingsDispatch }}>{children}</RatingsContext.Provider>
}

export const useRatingsContext = () => {
  const context = useContext(RatingsContext)
  if (context === undefined) throw new Error('useRatingsContext must be used within a RatingsProvider')
  return context
}
