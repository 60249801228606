import { LoginOutlined } from '@mui/icons-material'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import { Alert, Button, Card, TextField } from '@mui/material'
import { amber } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useUserContext } from './context'

export const Auth = ({ type }) => {
  const classes = useStyles()

  return (
    <div className={classes.heroImage}>
      <Card variant="outlined" className={classes.card}>
        <h1 className={classes.h1}>ODDE0TRAX</h1>
        <Header type={type} />
        <div className={classes.container}>
          <Google type={type} />
          <Facebook type={type} />
        </div>
        <Error type={type} />
        <Request type={type} />
      </Card>
    </div>
  )
}

export const Error = ({ type }) => {
  const { user, userActions, userDispatch } = useUserContext()

  useEffect(() => {
    userDispatch(userActions.clearError())
  }, [userActions, userDispatch, type])

  return user?.error && <Alert severity="error">{user?.error}</Alert>
}

export const Header = ({ type }) => {
  return type === 'signin' ? <h2 style={{ margin: 2 }}>Welcome Back!</h2> : <h2 style={{ margin: 2 }}>Request an Invitation to Join</h2>
}

export const Google = ({ type }) => {
  const classes = useStyles()
  const location = useLocation()
  const { userActions, userDispatch } = useUserContext()
  const loginWithGoogle = async () => userDispatch(userActions.authenticate('google', null, location))
  return (
    <Button onClick={loginWithGoogle} className={classes.button} sx={{ mb: 1 }} size="large" variant="outlined" color="primary" startIcon={<GoogleIcon />}>
      <span style={{ marginRight: 4 }}>{type === 'signin' ? 'Sign in' : 'Sign up'}</span> with Google
    </Button>
  )
}

export const Facebook = ({ type }) => {
  const classes = useStyles()
  const location = useLocation()
  const { userActions, userDispatch } = useUserContext()
  const loginWithFacebook = async () => userDispatch(userActions.authenticate('facebook', null, location))
  return type === 'signin' ? (
    <Button onClick={loginWithFacebook} className={classes.button} size="large" variant="outlined" color="primary" startIcon={<FacebookIcon />}>
      <span style={{ marginRight: 4 }}>Sign in </span> with Facebook
    </Button>
  ) : null
}

export const Email = ({ type }) => {
  const classes = useStyles()
  const location = useLocation()
  const { userActions, userDispatch } = useUserContext()
  const [creds, setCreds] = useState({ email: '', password: '' })
  const handleChange = (type) => (evt) => setCreds({ ...creds, [type]: evt.target.value })
  const loginWithEmailAndPass = async () => userDispatch(userActions.authenticate('emailAndPass', creds, location))
  const createUser = async () => userDispatch(userActions.createUser(creds))
  return (
    <form className={classes.form}>
      <TextField
        className={classes.input}
        onChange={handleChange('email')}
        value={creds.email}
        label="Email Address"
        variant="outlined"
        color="primary"
        margin="dense"
        type="email"
      />
      <TextField
        className={classes.input}
        onChange={handleChange('password')}
        value={creds.password}
        label="Password"
        variant="outlined"
        color="primary"
        margin="dense"
        type="password"
      />

      <Button
        onClick={type === 'signin' ? loginWithEmailAndPass : createUser}
        className={classes.button}
        sx={{ m: 1 }}
        size="large"
        variant="outlined"
        color="primary"
        startIcon={<LoginOutlined />}
      >
        {type === 'signin' ? 'Sign in' : 'Sign up'} with Email
      </Button>
    </form>
  )
}

export const Request = ({ type }) => {
  const classes = useStyles()
  return (
    <>
      <p className={classes.p}>Not yet available to the general public.</p>

      {type === 'signin' ? (
        <>
          <p className={classes.p}>If you would like to become a member, please:</p>
          <Link to="/request">Request an Invitation</Link>
        </>
      ) : (
        <p className={classes.p}>
          To request an invitation for membership, please use your <span style={{ fontWeight: 'bold' }}>Google</span> or <span style={{ fontWeight: 'bold' }}>Email</span> account.
        </p>
      )}
    </>
  )
}

export default Auth

const useStyles = makeStyles((theme) => ({
  heroImage: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("https://oddeotrax.nyc3.cdn.digitaloceanspaces.com/images/foam_back_1200.webp");',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 50px)',
    width: '100vw',
  },
  h1: { margin: '0 0 4px 0', fontFamily: 'Orbitron', fontSize: 40, color: amber[500], fontWeight: 400 },
  card: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 400,
    padding: 16,
    textAlign: 'center',
    borderTop: `4px solid ${amber[500]}`,
    marginBottom: 8,
  },
  input: { border: `1px solid ${amber[500]}`, display: 'flex', width: '80%' },
  p: { marginBottom: 4 },
  container: { margin: 16 },
  button: { width: '80%' },
}))
