import { Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { amber } from '@mui/material/colors'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("/img/foam_back_1350.jpg");',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
}))

export const Pending = (props) => {
  const classes = useStyles()

  return (
    <div
      className={classes.heroImage}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 'calc(100vh - 50px)', width: '100vw' }}
    >
      <Card
        variant="outlined"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: 400,
          height: 600,
          textAlign: 'center',
          borderTop: `4px solid ${amber[500]}`,
          padding: '1em',
        }}
      >
        &nbsp;
        <h1 style={{ margin: 0, fontFamily: 'Orbitron', fontSize: 40, color: amber[500], fontWeight: 400 }}>ODDE0TRAX</h1>
        <br />
        <h2>Thank you for your request!</h2>
        <h3>You will be contacted via email when an opening is available</h3>
        <p>
          At this time we are at max capacity.
          <br />
          <br />
          However, this changes on a weekly basis. <br /> Please allow 30 days before contacting our site support for more information
        </p>
      </Card>
    </div>
  )
}

export default Pending
