import { useState, useEffect } from 'react'
import EmailIcon from '@mui/icons-material/Email'
import { Button } from '@mui/material'
import { EmailProvider, useEmailContext } from './context'

export const AcceptedEmailButton = (props) => {
  return (
    <EmailProvider>
      <EmailButton {...props} />
    </EmailProvider>
  )
}

export const EmailButton = ({ sendToList, btnLabel, variant }) => {
  const { emailActions, emailDispatch } = useEmailContext()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    sendToList.length ? setDisabled(false) : setDisabled(true)
  }, [sendToList])

  const sendEmail = async (idList) => {
    if (Array.isArray(idList) && idList.length) emailDispatch(emailActions.sendAcceptedEmail(idList))
  }

  return (
    <Button onClick={() => sendEmail(sendToList)} disabled={disabled} variant={variant} startIcon={<EmailIcon />}>
      {btnLabel}
    </Button>
  )
}

export default AcceptedEmailButton
