export const types = {
  USER_LOGOUT: 'USER_LOGOUT',
  USER_FETCHING: 'USER_FETCHING',
  USER_FETCHED: 'USER_FETCHED',
  USER_FETCH_ERROR: 'USER_FETCH_ERROR',
  USER_AUTHENTICATING: 'USER_AUTHENTICATING',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  USER_UNAUTHENTICATED: 'USER_UNAUTHENTICATED',
  USER_AUTHENTICATION_ERROR: 'USER_AUTHENTICATION_ERROR',
  USER_CREATED: 'USER_CREATED',
  USER_CREATING: 'USER_CREATING',
  USER_CREATE_ERROR: 'USER_CREATE_ERROR',
  USER_VERIFICATION: 'USER_VERIFICATION',
  USER_SAVING: 'USER_SAVING',
  USERS_SAVED: 'USER_SAVED',
  USERS_SAVE_ERROR: 'USER_SAVE_ERROR',
  USER_UPDATING: 'USER_UPDATING',
  USER_ACCEPTED: 'USER_ACCEPTED',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
  ERROR_CLEAR: 'ERROR_CLEAR',
  USERS_LOADING: 'USERS_LOADING',
  USERS_LOADED: 'USERS_LOADED',
  USERS_LOAD_ERROR: 'USERS_LOAD_ERROR',
}
