import moment from 'moment'
import { Markup } from 'interweave'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Chip, Card, CardContent, Avatar, CardActionArea, CardHeader, CardMedia, Divider, Grid, Typography, Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { ProjectProvider, useProjectContext } from './context'
import qs from 'qs'
import Nav from '../app/nav'
import { TopRemixersList } from '../remixes/top-remixers'

const useStyles = makeStyles((theme) => ({
  root: { textDecoration: 'none' },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  sectionDesktop: { display: 'none', [theme.breakpoints.up('md')]: { display: 'flex' } },
  sectionMobile: { display: 'flex', [theme.breakpoints.up('md')]: { display: 'none' } },
  listMobile: { margin: 0, paddingTop: 10, [theme.breakpoints.up('md')]: { margin: 20 } },
}))

export const ErrorBanner = ({ message }) => {
  return (
    <Grid container alignItems="center" style={{ marginBottom: '20px', width: '100%' }}>
      <Grid item xs={12} style={{ width: '100%', verticalAlign: 'middle', lineHeight: '50px', height: 50, textAlign: 'center' }}>
        <Alert severity="error">{message}</Alert>
      </Grid>
    </Grid>
  )
}

export const ProjectItem = ({ slug, tags, title, artist, details, status, open_date, close_date }) => {
  const classes = useStyles()
  return (
    <Card style={{ width: '100%', margin: '0px 1px 20px 1px' }}>
      <CardActionArea component={Link} to={`./projects/${slug}` || ''}>
        <Grid container>
          <Grid item md={5} xs={12}>
            <CardMedia className={classes.media} image={details.image.src} title={details.image.title} alt={title} />
          </Grid>
          <Grid item md={7} xs={12}>
            <CardHeader
              avatar={<Avatar alt={artist.name} src={artist.image} sx={{ width: 56, height: 56 }} />}
              title={<span style={{ fontWeight: '500', color: '#fff', fontFamily: 'lato-regular', fontSize: '1.5rem' }}>{title}</span>}
              subheader={
                <span
                  style={{
                    textTransform: 'uppercase',
                    marginBottom: 0,
                    marginTop: 10,
                    color: 'rgb(153, 153, 153)',
                    fontWeight: 'bold',
                    fontFamily: 'lato-regular',
                    fontSize: '1.1em',
                    overflow: 'hidden',
                  }}
                >
                  {artist.name}
                </span>
              }
            />
            <CardContent style={{ color: '#fff' }}>
              {tags?.map((tag, i) => (
                <Chip style={{ marginRight: 5 }} key={i} color="primary" size="large" variant="outlined" label={tag} />
              ))}
              <p style={{ fontWeight: '500', color: '#fff', fontFamily: 'lato-regular', fontSize: '1.5rem' }}>{details.subTitle}</p>
              <p>
                <Markup content={details.summary} />
              </p>
              {(open_date ? moment().diff(moment(open_date)) < 0 : status === 'preview') ? (
                <Alert severity="info" style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 10, opacity: 0.9, width: '100%' }}>
                  COMING SOON
                </Alert>
              ) : null}
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

export const ProjectRow = ({ slug, title, artist, details }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <CardActionArea style={{ textDecoration: 'none', color: '#fff' }} component={Link} to={`./projects/${slug}` || ''}>
            <CardHeader
              title={<span style={{ fontWeight: '500', fontFamily: 'lato-regular' }}>{title}</span>}
              subheader={
                <span
                  style={{
                    textTransform: 'uppercase',
                    marginBottom: 0,
                    marginTop: 10,
                    color: 'rgb(153, 153, 153)',
                    fontWeight: 'bold',
                    fontFamily: 'lato-regular',
                    fontSize: '0.9em',
                    overflow: 'hidden',
                  }}
                >
                  {artist.name}
                </span>
              }
            />
            {details.image.src ? <CardMedia className={classes.media} image={details.image.src} title={details.image.title} /> : <span>Loading</span>}
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  )
}

export const List = (props) => {
  return (
    <ProjectProvider>
      <Projects {...props} />
    </ProjectProvider>
  )
}

export const Projects = (props) => {
  const { state, projectActions, projectDispatch } = useProjectContext()
  const { search } = useLocation()
  const qsparam = qs.parse(search, { ignoreQueryPrefix: true })
  const [showError] = useState(qsparam.e || '')
  const classes = useStyles()

  useEffect(() => {
    projectDispatch(projectActions.list())
  }, [projectDispatch, projectActions])

  const preProjects = state?.projects.filter((p) => (p.open_date ? moment().diff(moment(p.open_date)) < 0 : p.status === 'preview'))
  const openProjects = state?.projects.filter((p) => (p.open_date ? moment().diff(moment(p.open_date)) >= 0 && moment().diff(moment(p.close_date)) < 0 : p.status === 'open'))
  const closedProjects = state?.projects.filter((p) => (p.close_date ? moment().diff(moment(p.close_date)) >= 0 : p.status === 'closed'))

  return (
    <Box style={{ display: 'flex', alignContent: 'center', width: 1200, backgroundColor: '#121212' }}>
      <Box className={classes.sectionDesktop} style={{ minWidth: 200, flexDirection: 'column', backgroundColor: '#212121' }}>
        <Divider style={{ marginTop: 15, borderColor: 'transparent' }} />
        <Nav />
        <Divider style={{ marginTop: 10 }} />
        <TopRemixersList />
      </Box>
      <Box className={classes.listMobile} style={{ display: 'flex', alignContent: 'center', flexDirection: 'column', overflow: 'scroll' }}>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: '1em' }}>
          {showError === 'pnf' && <ErrorBanner message={'Project Not Found: Try Again'} />}
          <Typography style={{ fontWeight: '500', fontFamily: 'orbitron' }} variant="h4" component="h1">
            Projects
          </Typography>
          <br />
          <Grid container spacing={2}>
            {preProjects.map((p) => (
              <Grid key={p._id} container item xs={12} alignItems="center">
                {p.artist ? <ProjectItem {...p} /> : <span />}
              </Grid>
            ))}
            {openProjects.map((p) => (
              <Grid key={p._id} container item xs={12} alignItems="center">
                {p.artist ? <ProjectItem {...p} /> : <span />}
              </Grid>
            ))}
          </Grid>
          <br />
          <Typography style={{ fontWeight: '500', fontFamily: 'orbitron' }} variant="h4" component="h1">
            Completed
          </Typography>
          <br />
          <Divider />
          <br />
          <Grid container spacing={2}>
            {closedProjects.map((p) => (
              <Grid key={p._id} container item md={4} sm={6} xs={12} alignItems="center">
                {p.artist ? <ProjectRow {...p} /> : <span />}
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    </Box>
  )
}

export default List
