import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  sending: true,
}

const EmailContext = createContext()

export const EmailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const emailDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.email = state
  return <EmailContext.Provider value={{ state, emailActions: actions, emailDispatch }}>{children}</EmailContext.Provider>
}

export const useEmailContext = () => {
  const context = useContext(EmailContext)
  if (context === undefined) throw new Error('useEmailContext must be used within an EmailProvider')
  return context
}
