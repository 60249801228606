export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'RELEASES_LOADED':
      return { ...state, releases: payload, loading: false }
    case 'RELEASE_LOADED':
      return { ...state, release: payload, loading: false }

    default:
      return state
  }
}

export default reducer
