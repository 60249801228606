import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: true,
  remix: {
    _id: '',
    project_slug: '',
    genre: '',
    remixer: {
      _id: '',
      name: '',
      avatar: '',
    },
    created: {
      by: '',
      date: '',
    },
    ratings: [],
  },
  remixes: [],
}

const RemixContext = createContext()

export const RemixProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const remixDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.feed = state
  return <RemixContext.Provider value={{ state, remixActions: actions, remixDispatch }}>{children}</RemixContext.Provider>
}

export const useRemixContext = () => {
  const context = useContext(RemixContext)
  if (context === undefined) throw new Error('useRemixContext must be used within a RemixProvider')
  return context
}
