import moment from 'moment'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ProjectProvider, useProjectContext } from './context'
import ProjectActions from './project-actions'
import ProjectHeading from './project-heading'
import RemixList from '../remixes/list'

export const Project = (props) => {
  return (
    <ProjectProvider>
      <ProjectPage {...props} />
    </ProjectProvider>
  )
}

export const ProjectPage = ({ computedMatch, ...props }) => {
  const { state, projectActions, projectDispatch } = useProjectContext()
  const projectSlug = computedMatch?.params?.project
  const [hasError, setHasError] = useState(false)
  const [currentSong, setCurrentSong] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [songs, setSongs] = useState([])
  const history = useHistory()
  const { project } = state
  let audioPlayer = useRef('audio_tag')
  let songList = songs ? songs?.map((song) => song.url) : []
  songList.unshift(project.details.demoTrack)

  const playPause = (song) => {
    let songUrl = songList[song]
    let paused = audioPlayer.current.paused
    if (songUrl === audioPlayer.current.src) {
      if (paused) {
        setPlaying(true)
        audioPlayer.current.play()
      } else {
        setPlaying(false)
        audioPlayer.current.pause()
      }
    } else {
      setCurrentSong(song)
      setPlaying(true)
      audioPlayer.current.src = songUrl
      audioPlayer.current.play()
    }
  }

  useEffect(() => {
    projectDispatch(projectActions.load(projectSlug))
  }, [projectDispatch, projectActions, projectSlug])

  useEffect(() => {
    const loadProject = (p) => {
      try {
        if (p === null) throw Error('Project is null')
      } catch {
        setHasError(true)
        history.push('/projects?e=pnf')
      }
    }
    loadProject(project)
  }, [project, history])

  const handleDownloadClick = () => {
    projectDispatch(projectActions.trackDownload(projectSlug))
  }

  return (
    <>
      {!hasError && (
        <article style={{ alignContent: 'center', width: 1100, marginTop: '10px', marginBottom: 30 }}>
          {(project.open_date ? moment().diff(moment(project.open_date)) >= 0 && moment().diff(moment(project.close_date)) < 0 : project.status === 'open') ? (
            <ProjectActions project={project} handleDownloadClick={handleDownloadClick} />
          ) : (
            ''
          )}
          <main>
            <section style={{ marginTop: '10px' }}>
              <ProjectHeading currentSong={currentSong} playing={playing} playPause={playPause} project={project} />
            </section>
            {(project.open_date ? moment().diff(moment(project.open_date)) >= 0 && moment().diff(moment(project.close_date)) < 0 : project.status === 'open') ? (
              <section>
                <Typography style={{ fontWeight: '500', fontFamily: 'orbitron' }} variant="h4" component="h1">
                  &nbsp;Submissions
                </Typography>
                <RemixList setSongs={setSongs} currentSong={currentSong} playing={playing} playPause={playPause} project_slug={projectSlug} />
              </section>
            ) : (
              <span></span>
            )}
          </main>
          <audio ref={audioPlayer} type="audio/mpeg" preload="true" />
        </article>
      )}
      {hasError && (
        <div style={{ textAlign: 'center' }}>
          <Alert severity="error">Project Not Found: Redirecting</Alert>
        </div>
      )}
    </>
  )
}

export default Project
