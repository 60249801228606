import React from 'react'
import { Switch, BrowserRouter as Router, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import FeedIcon from '@mui/icons-material/Feed'
import AlbumIcon from '@mui/icons-material/Album'
import PlayForWorkIcon from '@mui/icons-material/PlayForWork'
import PeopleIcon from '@mui/icons-material/People'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import clsx from 'clsx'
import theme from './theme'
import './index.css'
import log from 'loglevel'

import Auth from '../users/auth'
import { UserProvider, useUserContext } from '../users/context'
import UserInvitationRequests from '../users/invitations'
import Header from './header'
import Home from '../home'
import Feed from '../feed'
import Project from '../projects'
import ProjectCreate from '../projects/create'
import Projects from '../projects/list'
import Upload from '../upload'
import NotFound from '../notfound'
import Admin from '../home/admin'
import TermsOfService from '../home/tos'
import PrivacyPolicy from '../home/privacy'
import RemixAgreement from '../home/remix-agreement'
import Profile from '../users/profile'

import Pending from '../home/pending'
import AccountCreate from '../accounts/create'
import Accounts from '../accounts/list'
import Releases from '../releases/list'
import Release from '../releases'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <UserProvider>
          <Pages />
        </UserProvider>
      </Router>
    </ThemeProvider>
  )
}

const PrivateRoute = ({ Component, ...props }) => {
  const location = useLocation()
  const { user } = useUserContext()
  return (
    <Route {...props}>
      {user.authenticated && user.accepted ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `/signin`, search: `redirect=${location.pathname}${location.search}`, state: { from: location } }} />
      )}
    </Route>
  )
}

export const Pages = (props) => {
  if (process.env.REACT_APP_ENV_DEV) {
    log.setLevel('debug')
  }

  const classes = useStyles()
  const { user } = useUserContext()
  const history = useHistory()
  const location = useLocation()

  //useGoogleAnalytics()
  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>
        {user.loading ? (
          <div>Loading</div>
        ) : (
          <Switch>
            <Auth type="signin" path="/signin" />
            <Auth type="signout" exact path="/signout" />
            <Auth type="signup" exact path="/request" />
            <TermsOfService path="/terms-of-service" />
            <PrivacyPolicy path="/privacy" />
            <Release exact path="/releases/:artist/:release" />
            <PrivateRoute exact path="/admin" Component={Admin} />
            <PrivateRoute exact path="/standard-remix-agreement" Component={RemixAgreement} />
            <PrivateRoute exact path="/profile" Component={Profile} />
            <PrivateRoute exact path="/artists/create" Component={AccountCreate} />
            <PrivateRoute exact path="/artists" Component={Accounts} />
            <PrivateRoute exact path="/releases/:artist" Component={Releases} />
            <PrivateRoute exact path="/releases" Component={Releases} />
            <PrivateRoute exact path="/projects" Component={Projects} />
            <PrivateRoute exact path="/projects/create" Component={ProjectCreate} />
            <PrivateRoute exact path="/projects/:project" Component={Project} />
            <PrivateRoute exact path="/upload/:slug" Component={Upload} />
            <PrivateRoute exact path="/upload" Component={Upload} />
            <PrivateRoute exact path="/users/invitations" Component={UserInvitationRequests} />

            {user.authenticated && !user.accepted ? <Pending /> : user.authenticated && user.accepted ? <Feed exact path="/" /> : <Home exact path="/" />}
            <NotFound path="*" />
          </Switch>
        )}
      </main>
      {user.authenticated && user.accepted ? (
        <BottomNavigation
          onChange={(event, newValue) => history.push(`/${newValue}`)}
          value={location.pathname.split('/')[1]}
          showLabels
          sx={{ display: { md: 'none', xs: 'flex' } }}
          className={clsx(classes.bottomNav)}
        >
          <BottomNavigationAction value="" label="Feed" icon={<FeedIcon size="lg" />} />
          <BottomNavigationAction value="projects" label="Projects" icon={<PlayForWorkIcon size="lg" />} />
          <BottomNavigationAction value="artists" label="Artists" icon={<PeopleIcon size="lg" />} />
          <BottomNavigationAction value="releases" label="Releases" icon={<AlbumIcon size="lg" />} />
        </BottomNavigation>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100%',
    paddingBottom: 40,
    backgroundColor: '#121212',
    backgroundImage: 'linear-gradient(#121212,  #202020)',
    backgroundAttachment: 'fixed',
  },
  content: { display: 'flex', flex: 1, marginTop: 45, height: '100%', width: '100vw', justifyContent: 'center', overflow: 'hidden' },
  bottomNav: { width: '100%', position: 'fixed', bottom: 0, borderTop: '1px solid #212121', zIndex: 10 },
}))

export default App
