import { types } from './constants'

export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case types.USER_CREATING:
      return { ...state, error: '' }
    case types.USER_CREATE_ERROR:
    case types.USER_SAVING:
      return { ...state, error: '' }
    case types.USER_SAVE_ERROR:
      return { ...state, error: getError(payload.error) }
    case types.USER_SAVED:
      return { ...state, loading: false }
    case types.USER_AUTHENTICATION_ERROR:
      return { ...state, error: getError(payload.error) }
    case types.ERROR_CLEAR:
      return { ...state, error: '' }
    case types.USER_LOGOUT:
      return { ...state, authenticated: false }
    case types.USER_SIGNIN:
      return { ...state, signingIn: true }
    case types.USER_SIGNOUT:
      return { ...state, signingIn: false }
    case types.USER_AUTHENTICATED:
      return { ...state, authenticated: payload.authenticated, loading: false }
    case types.USER_UNAUTHENTICATED:
      return { ...state, authenticated: false, loading: false }
    case types.USER_ACCEPTED:
      return {
        ...state,
        loading: false,
      }
    case types.USER_FETCHED:
      return {
        ...state,
        id: payload._id,
        familyName: payload.familyName,
        givenName: payload.givenName,
        name: payload.name,
        avatar: payload.avatar,
        alias: payload.alias,
        authenticated: !!payload._id,
        accepted: payload.accepted,
        email: payload.email,
        paypalEmail: payload.paypalEmail,
        role: payload.role,
        loading: false,
      }
    case 'USERS_LOADED':
      return { ...state, users: payload, loading: false }
    default:
      return state
  }
}

export const getError = (error) => {
  switch (error) {
    case 'Firebase: Error (auth/email-already-in-use).':
      return 'Email address is not available'
    case 'Firebase: Error (auth/wrong-password).':
      return 'Incorrect email, password or provider'
    default:
      return ''
  }
}

export default reducer
