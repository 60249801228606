import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: true,
  project: {
    _id: '',
    title: '',
    artist: {
      _id: '',
      name: '',
      image: '',
    },
    details: {
      image: {
        title: '',
        src: '',
      },
      summary: '',
      content: '',
      demoTrack: '',
      stems: '',
    },
    slug: '',
    interactions: {
      viewed_by: [],
      downloaded_by: [],
    },
    tags: [],
    status: '',
    visibility: '',
    styles: {
      backgroundSize: '',
      backgroundColor: '',
      fontSize: '',
    },
    remixes: [],
    open_date: '',
    close_date: '',
  },
  projects: [],
}

const ProjectContext = createContext()

export const ProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const projectDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.projects = state
  return <ProjectContext.Provider value={{ state, projectActions: actions, projectDispatch }}>{children}</ProjectContext.Provider>
}

export const useProjectContext = () => {
  const context = useContext(ProjectContext)
  if (context === undefined) throw new Error('useProjectContext must be used within an ProjectProvider')
  return context
}
