import axios from 'axios'

export const types = {
  PROJECT_LOADING: 'PROJECT_LOADING',
  PROJECT_LOADED: 'PROJECT_LOADED',
  PROJECT_LOAD_ERROR: 'PROJECT_LOAD_ERROR',

  PROJECTS_LOADING: 'PROJECTS_LOADING',
  PROJECTS_LOADED: 'PROJECTS_LOADED',
  PROJECTS_LOAD_ERROR: 'PROJECTS_LOAD_ERROR',
}

export const create =
  (newProject) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.PROJECT_LOADING, payload: newProject })
    const result = await axios.post(`/api/projects`, newProject)
    if (result.error) {
      dispatch({ type: types.PROJECT_LOAD_ERROR, payload: result.error })
    } else {
      dispatch({ type: types.PROJECT_LOADED, payload: result.data })
    }
  }

export const list =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.PROJECTS_LOADING, payload: true })
    const result = await axios.get(`/api/projects`)
    if (result.error) dispatch({ type: types.PROJECTS_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.PROJECTS_LOADED, payload: result.data })
  }

export const load =
  (slug) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.PROJECT_LOADING, payload: true })
    const result = await axios.get(`/api/projects/slug/${slug}`)
    if (result) {
      const eventData = {
        action: 'view',
        data: { slug: slug },
        domain: 'projects',
      }
      await axios.post(`/api/evt`, eventData)
    }
    if (result.error) dispatch({ type: types.PROJECT_LOAD_ERROR, payload: { error: result.error } })

    dispatch({ type: types.PROJECT_LOADED, payload: result.data })
  }

export const trackDownload =
  (slug) =>
  async ({ dispatch, history }) => {
    const eventData = {
      action: 'download',
      data: { slug: slug },
      domain: 'projects',
    }
    await axios.post(`/api/evt`, eventData)
  }
