import React, { useEffect, useState } from 'react'
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { AccountProvider, useAccountContext } from '../context'

export const AccountSelectControl = (props) => {
  return (
    <AccountProvider>
      <SelectBox {...props} />
    </AccountProvider>
  )
}

export const SelectBox = ({ params, ...props }) => {
  const { state, accountActions, accountDispatch } = useAccountContext()
  const { accounts } = state
  const { required } = props
  const { fullWidth } = props
  const [defaultValue, setDefaultValue] = useState('')
  const artistAccounts = accounts.filter((account) => account.type === 'artist')

  useEffect(() => {
    accountDispatch(accountActions.list('artists'))
  }, [accountDispatch, accountActions])

  const handleChange = (event) => {
    setDefaultValue(event.target.value)
  }

  return (
    <Box xs={{ minWidth: 120 }}>
      <FormControl required={required} fullWidth={fullWidth}>
        <InputLabel id="select-account-label">Select an Artist</InputLabel>
        <Select labelId="select-account-label" id="select-account" value={defaultValue} variant="standard" label="Artist" onChange={handleChange}>
          {artistAccounts?.map((a) => (
            <MenuItem key={a._id} value={a._id}>
              {a.name}
            </MenuItem>
          ))}
        </Select>
        <input id="select-account-value" value={defaultValue} style={{ display: 'none' }} readOnly />
      </FormControl>
    </Box>
  )
}

export default AccountSelectControl
