import axios from 'axios'

export const types = {
  RATINGS_LOADING: 'RATINGS_LOADING',
  RATINGS_LOADED: 'RATINGS_LOADED',
  RATINGS_LOAD_ERROR: 'RATINGS_LOAD_ERROR',
}

export const create =
  (rating) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.RATINGS_LOADING, payload: true })
    const ratingData = {
      track_id: rating.track_id,
      attribute: rating.attribute,
      value: rating.value,
    }
    const result = await axios.post(`/api/ratings`, ratingData)
    if (result.error) dispatch({ type: types.RATINGS_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.RATINGS_LOADED, payload: result.data })
  }

export const load =
  (track_id, rating_attribute) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.RATINGS_LOADING, payload: true })
    const result = await axios.get(`/api/ratings/id/${track_id}/type/${rating_attribute}`)
    if (result.error) dispatch({ type: types.RATINGS_LOAD_ERROR, payload: { error: result.error } })

    dispatch({ type: types.RATINGS_LOADED, payload: result.data })
  }

export const ratingsChange =
  (track_id) =>
  async ({ dispatch, history }) => {
    const eventData = {
      action: 'change',
      data: { slug: track_id },
      domain: 'ratings',
    }
    await axios.post(`/api/evt`, eventData)
  }
