import axios from 'axios'

export const types = {
  FEED_LOADING: 'FEED_LOADING',
  FEED_LOADED: 'FEED_LOADED',
  FEED_LOAD_ERROR: 'FEED_LOAD_ERROR',
}

export const list =
  (type) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.FEED_LOADING, payload: true })
    const result = await axios.get(`/api/feed`)
    if (result.error) dispatch({ type: types.FEED_LOAD_ERROR, payload: { error: result.error } })
    dispatch({ type: types.FEED_LOADED, payload: result.data })
  }

export const feedClick =
  (slug) =>
  async ({ dispatch, history }) => {
    const eventData = {
      action: 'click',
      data: { slug: slug },
      domain: 'feeds',
    }
    await axios.post(`/api/evt`, eventData)
  }
