import HeadphonesIcon from '@mui/icons-material/Headphones'
import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
import NotFound from '../notfound'
import Subscribe from '../subscribers'
import { useUserContext } from '../users/context'
import { ReleaseProvider, useReleaseContext } from './context'

export const Release = (props) => {
  return (
    <ReleaseProvider>
      <ReleasePage {...props} />
    </ReleaseProvider>
  )
}

export const ReleasePage = ({ computedMatch, params, ...rest }) => {
  const { state, releaseActions, releaseDispatch } = useReleaseContext()
  const { user } = useUserContext()
  const releaseSlug = computedMatch?.params?.release
  const { release, loading } = state

  useEffect(() => {
    releaseDispatch(releaseActions.load(releaseSlug))
  }, [releaseDispatch, releaseActions, releaseSlug, user])

  const classes = useStyles()

  const srcDocStyle =
    '<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>'
  const srcDocLink = `<a href=${release?.media.video_url}><img src=${release?.media.video_thumb} alt="${release?.title} Video"><span>▶</span></a>`
  const srcDocBody = srcDocStyle + srcDocLink
  const metaDescription = `Listen to ${release?.tags[0]} and ${release?.tags[1]} music from ${release?.artist.name} on ODDEOTRAX`

  if (!loading && (!release || !release?.title))
    return (
      <NotFound
        type="release"
        text={
          <span>
            Would you like to <Link to="/releases">see all releases</Link> instead?
          </span>
        }
      />
    )

  return (
    <article className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={metaDescription} />
        <title>ODDEOTRAX</title>
      </Helmet>
      <Grid
        item
        justifyContent="center"
        style={{
          backgroundImage: "url('" + release?.image + "')",
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          bacgroundPosition: 'top',
          backgroundSize: 'cover',
          opacity: 0.3,
          filter: 'blur(35px)',
          zIndex: '0',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          content: '',
        }}
      ></Grid>
      <Grid container justifyContent="center" style={{ backgroundColor: '#121212' }}>
        <Grid container item className={classes.header}>
          <Grid item xs={12}>
            <Typography style={{ fontFamily: 'Orbitron' }} className={classes.headline} variant="h1">
              {release?.artist.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontFamily: 'Lato-Light' }} className={classes.sub_headline} variant="h2">
              {release?.tags[0]} | {release?.tags[1]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item xs={12}>
            <Button variant="outlined" target="_blank" href={release?.shops[0]?.url || ''} className={classes.cta_button} endIcon={<HeadphonesIcon size="lg" />}>
              Listen on {release?.shops[0]?.vendor}
            </Button>
          </Grid>
          <Grid item className={classes.media_container} sm={6} xs={12}>
            <Card>
              <CardActionArea>
                <CardMedia>
                  <LazyLoad>
                    <iframe
                      width="100%"
                      height="315"
                      src={release?.media.video_url}
                      srcDoc={srcDocBody}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </LazyLoad>
                </CardMedia>
              </CardActionArea>
              <CardContent>
                <Typography variant="h3" className={classes.media_headline}>
                  {release?.media.headline}
                </Typography>
                <br />
                <Typography variant="body2" className={classes.media_body}>
                  {release?.media.body}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <Grid container item justifyContent="center" className={classes.shop_container} sm={6} xs={12}>
            {release?.shops?.slice(1).map((s) => (
              <Grid item key={s.vendor} xs={12}>
                <Button variant="outlined" target="_blank" href={s?.url || ''} className={classes.shop_button}>
                  {s?.vendor}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footer_container}>
          <Subscribe form="2863807" tags={[release?.artist.subscribe_key]} />
          <br />
          <Typography variant="body1">
            <a className={classes.footer_link} href={release?.copyright.holder_url} target="_blank" rel="noreferrer">
              &copy; {release?.copyright.holder} {release?.copyright.year}
            </a>
          </Typography>
          <br />
        </Grid>
      </Grid>
    </article>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1100,
    textAlign: 'center',
  },
  header: {
    paddingTop: 100,
    paddingBottom: 30,
  },
  headline: {
    '&.MuiTypography-root': {
      padding: 0,
      color: 'white',
      letterSpacing: '5px',
      fontSize: '5rem',
      [theme.breakpoints.down('sm')]: { fontSize: '9vw' },
    },
  },
  sub_headline: {
    '&.MuiTypography-root': {
      color: 'white',
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: { fontSize: '7vw' },
    },
  },
  cta_button: {
    '&.MuiButton-root': {
      color: '#ffc107',
      borderColor: '#ffc107',
      marginBottom: 20,
      width: '50%',
      [theme.breakpoints.down('sm')]: { width: '98%' },
    },
    '&.MuiButton-root:hover': {
      color: '#ffc107',
    },
  },
  media_container: {
    width: '100%',
  },
  media_headline: {
    '&.MuiTypography-root': {
      color: 'white',
      fontFamily: 'Lato-Regular',
      fontDisplay: 'swap',
      fontSize: '1.7rem',
      [theme.breakpoints.down('sm')]: { fontSize: '6vw' },
    },
  },
  media_body: {
    color: 'white',
    fontFamily: 'Lato-Light',
    fontDisplay: 'swap',
  },
  shop_container: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
  },
  shop_button: {
    width: '100%',
    '&.MuiButton-root': {
      color: '#fff',
      borderColor: '#424242',
      marginTop: 2,
      [theme.breakpoints.down('sm')]: { width: '98%' },
    },
    '&.MuiButton-root:hover': {
      color: '#fff',
      backgroundColor: '#555',
    },
  },
  footer_container: {
    paddingTop: 10,
  },
  footer_link: {
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
    },
  },
}))

export default Release
