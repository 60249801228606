import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: false,
  progress: 0,
  selectedFile: {},
  uploadedFile: {},
  error: ''
}

const UploadContext = createContext()

export const UploadProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const uploadDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.upload = state
  return <UploadContext.Provider value={{ state, uploadActions: actions, uploadDispatch }}>{children}</UploadContext.Provider>
}

export const useUploadContext = () => {
  const context = useContext(UploadContext)
  if (context === undefined) throw new Error('useUploadContext must be used within an UploadProvider')
  return context
}
