import { Card, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { amber } from '@mui/material/colors'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export const RemixAgreement = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100vw' }}>
      <Card
        variant="outlined"
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          width: 1100,
          padding: '1em',
          color: 'black',
        }}
      >
        &nbsp;
        <h1 style={{ margin: 0, fontFamily: 'Orbitron', fontSize: 40, color: amber[500], fontWeight: 400 }}>ODDE0TRAX</h1>
        <Typography variant="h5">STANDARD REMIX AGREEMENT</Typography>
        <Typography variant="p">
          <strong>By submitting a remix, the Licensor agrees to all terms and conditions provided below.</strong>
          <br />
          <i>All agreements are void if a .wav file formatted according to the premaster specifications are not provided to Daniel Jones within 14 days of the project end date.</i>
          <br />
          <br />
          <strong>Label (Licensee)</strong>: Oddeo Netwerx
          <br />
          <br />
          <strong>Licensor</strong>: The person submitting the remix, i.e. <strong>you</strong> are the Licensor
          <br />
          <br />
          <strong>Premasters:</strong>
          <ul>
            <li>
              <strong>Preferred</strong>: Uncompressed & Unlimited 24 bit, 96kHz .Wav with -3db headroom
            </li>
            <li>
              <strong>Minimum</strong>: Uncompressed & Unlimited 16 bit, 44.1kHz .Wav with -2db headroom
            </li>
          </ul>
          <strong>Label Rights</strong>: Exclusive rights to exploit the master(s) Territory: Universe Term: 5 years
          <br />
          <br />
          <strong>Advance</strong>: Specified in the project description
          <br />
          <br />
          <strong>Royalty</strong>: 35% percent of net profit generated from any and all sales of Licensor's remix.
          <ul>
            <li>After 50 sales, the Licensor shall receive 40% of net profit generated from their remix</li>
            <li>After 100 sales, the Licensor shall receive 50% of net profit generated from their remix</li>
          </ul>
          <strong>Licensing</strong>: 35% of any and all profit generated from third party licensing of the Licensor's remix.
          <br />
          <br />
          <strong>Copyright</strong>: ODDEO NETWERX assumes control of all rights, titles, purposes, and interests in and of the copyright of the remix for the entire agreed upon
          term of this contract.
          <br />
          <br />
          <strong>Accounting</strong>: Quarterly
          <br />
          <br />
          <strong>Payment</strong>: Paypal
          <br />
          <br />
          <strong>Samples</strong>: ODDEO NETWERX assumes that all signed artists have cleared any and all audio samples used in their tracks. We cannot be held responsible for any
          copyright infringement disputes that may arise as a result of an artist’s failure to do so. By signing this contract you accept full responsibility for the use of all
          audio in your tracks. If a case should arise, the tracks in question will be removed indefinitely from all stores and a hold put on any outstanding royalties as a
          resolution is attempted. If unsuccessful, this contract becomes null and void. The tracks will be dropped from the catalog, masters deleted, and no outstanding royalty
          payments will be made.
          <br />
          <br />
          <strong>***PLEASE NOTE</strong>: Paid within 30 days after Quarter's end. Amounts less than $15 will be rolled over to be included in the following quarters payments.
          <br />
          <br />
          Last Update: Version 1 - January 1, 2021 by Daniel Jones
        </Typography>
      </Card>
    </div>
  )
}

export default RemixAgreement
