import axios from 'axios'

export const types = {
  EMAIL_SENDING: 'EMAIL_SENDING',
  EMAIL_SENT: 'EMAIL_SENT',
  EMAIL_SEND_ERROR: 'EMAIL_SEND_ERROR',
}

export const sendAcceptedEmail =
  (idList) =>
  async ({ dispatch, history }) => {
    dispatch({ type: types.EMAIL_SENDING, payload: true })
    const sendToList = {
      senders: idList,
    }
    const result = await axios.post(`/api/email/accepted`, sendToList)
    if (result.error) dispatch({ type: types.EMAIL_SEND_ERROR, payload: { error: result.error } })

    dispatch({ type: types.EMAIL_SENT, payload: result.data })
  }
