import React, { createContext, useCallback, useContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import * as actions from './actions'
import reducer from './reducers'

const initalState = {
  loading: false,
  subscriber: {},
  error: {},
}

const SubscriberContext = createContext()

export const SubscriberProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState)
  const history = useHistory()
  const subscriberDispatch = useCallback((fn) => fn({ dispatch, history }), [history])

  window.reactState = window.reactState || {}
  window.reactState.upload = state
  return <SubscriberContext.Provider value={{ state, subscriberActions: actions, subscriberDispatch }}>{children}</SubscriberContext.Provider>
}

export const useSubscriberContext = () => {
  const context = useContext(SubscriberContext)
  if (context === undefined) throw new Error('useSubscriberContext must be used within a SubscriberProvider')
  return context
}
