export const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'SUBSCRIBER_LOADING':
      return { ...state, loading: true }
    case 'SUBSCRIBER_LOADED':
      return { ...state, subscriber: payload, loading: false }
    case 'SUBSCRIBER_LOAD_ERROR':
      return { ...state, error: payload, loading: false }
    default:
      return state
  }
}

export default reducer
