export const MiniAudioPlayer = ({ size, color, left, playPause, currentSong, song, playing }) => {
  return (
    <div aria-label="play/pause" onClick={() => playPause(song)}>
      {currentSong === song && playing ? (
        <div style={{ width: size, height: size, backgroundColor: color, transition: 'all 0.2s ease-out' }} />
      ) : (
        <div
          style={{
            width: 0,
            height: 0,
            borderTop: `${size / 2}px solid transparent`,
            borderBottom: `${size / 2}px solid transparent`,
            borderLeft: `${size}px solid ${color}`,
            transition: 'all 0.2s ease-out',
            marginLeft: left,
          }}
        />
      )}
    </div>
  )
}

export default MiniAudioPlayer
